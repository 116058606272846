import React, {useState} from "react";
import Table from "react-bootstrap/Table";
import {isNonEmptyArray} from "../../../utils/arrayUtils";
import TablePagination from "./TablePagination";

export function initialPageData() {
    return Object.assign({}, { currentPage: 1, pageSize: 25 });
}

export function initialSmallPageData() {
    return Object.assign({}, { currentPage: 1, pageSize: 10 });
}

export function LoadingTable({ isLoading, initialPageSize, columns, fields, items, rowProvider, onNavChange }) {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(initialPageSize);

    function pageSelected(page) {
        setCurrentPage(page);
        onNavChange(page, pageSize);
    }

    function pageSizeSelected(pageSize) {
        setCurrentPage(1);
        setPageSize(pageSize);
        onNavChange(1, pageSize);
    }

    return (
        <>
            <Table striped={!isLoading && isNonEmptyArray(items)} bordered hover={!isLoading && isNonEmptyArray(items)}>
                <thead>
                <tr>
                    {columns.map((column, index) => (<th key={index}>{column}</th>))}
                </tr>
                </thead>
                <tbody>
                {isLoading
                    ? (<tr><td colSpan={columns.length} align="center">Nalagam...</td></tr>)
                    : isNonEmptyArray(items)
                        ? items.map((item, index) =>
                            rowProvider
                                ? rowProvider(item, index)
                                : <tr key={item[fields[0]]}>
                                    {fields.map((field, index) => <td key={index}>{item[field]}</td>)}
                                </tr>)
                        : (<tr><td colSpan={columns.length} align="center">Ni zadetkov</td></tr>) }
                </tbody>
            </Table>

            <TablePagination
                selectedPage={currentPage}
                selectedPageSize={pageSize}
                disableNext={!Array.isArray(items) || items?.length < pageSize}
                onPageSelected={pageSelected}
                onPageSizeSelected={pageSizeSelected}
            />
        </>
    );
}