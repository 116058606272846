import React from "react";
import './PageTitle.css';

export default function PageTitle({ title, children }) {
    return (
        <div className="d-flex justify-content-between page-title">
            <h4>{title}</h4>
            {children}
        </div>
    )
}