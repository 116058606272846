import React from "react";
import PropTypes from "prop-types";
import './LoginTextField.css';
import '../../../resources/styles/Common.css';

export default function LoginTextField(props) {
    return (
        <div className="login-text-field">
            <div className="container">
                <label
                    className="main-text-color text-large"
                    htmlFor={props.id}
                >
                    {props.title}
                </label>
                {props.children}
            </div>
            <input
                className="main-border-color text-large"
                type={props.type}
                id={props.id}
                onChange={props.onChange}
                onKeyPress={props.onKeyPress}
                value={props.value}
                defaultValue={props.defaultValue}
                tabIndex={props.tabIndex || 1}
                autoComplete={props.autoComplete || 'on'}/>
        </div>
    );
}

LoginTextField.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    defaultValue: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    tabIndex: PropTypes.number,
    autoComplete: PropTypes.string,
}