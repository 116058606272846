import React, {useEffect, useState} from "react";
import Toast from "react-bootstrap/Toast";

function Snackbar(props) {
    const [message, setMessage] = useState(null);
    const [isError, setError] = useState(false);

    const resetMessage = () => {
        setMessage(null);
        props.updateMessage && props.updateMessage(null);
        props.updateErrorMessage && props.updateErrorMessage(null);
    }

    useEffect(() => {
        const newMessage = props.message || props.errorMessage;
        setMessage(newMessage);
        setError(props.errorMessage != null);
    }, [props.message, props.errorMessage]);

    return (
        <div className="position-fixed p-3" style={{ zIndex: '5', left: '50%', transform: 'translateX(-50%)', bottom: '0'}}>
            <Toast show={message || false} className={`alert ${isError ? 'bg-danger' : 'bg-dark'} text-white`} delay={3000} autohide animation={false} onClose={resetMessage}>
                <Toast.Body>{message}</Toast.Body>
            </Toast>
        </div>
    );
}

export default Snackbar;