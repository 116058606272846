import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import LoginTitle from "../Login/common/LoginTitle";
import LoginSpacing from "../Login/common/LoginSpacing";
import LoginTextField from "../Login/common/LoginTextField";
import {LoginButton} from "../Login/common/LoginButtons";
import button from "bootstrap/js/src/button";
import Snackbar from "../common/Snackbar";
import {searchBook} from "../../api/BooksApi";
import useToken from "../../utils/hooks/useToken";

export default function Search({ bookId, onPageSelected, ...props }) {
    const { token } = useToken();

    const [search, setSearch] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [showNoResults, setShowNoResults] = useState(false);

    const [message, updateMessage] = useState(null);

    const pageSelected = page => {
        onPageSelected(page);
        props.onHide();
    }

    const handleChange = e => {
        setShowNoResults(false);
        setSearch(e.target.value);
    }

    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();

            find();
        }
    }

    const find = async () => {
        if (search.length < 3) {
            updateMessage('Iskalni niz mora imeti vsaj 3 znake');
            return;
        }

        const results = await searchBook(token, bookId, search);
        const isError = results instanceof Error || (results.hasOwnProperty('type') && results.type === 'error');

        if (isError) {
            updateMessage('Napaka pri iskanju');
        } else if (!results.length) {
            setShowNoResults(true);
        } else {
            setSearchResults(results);
        }
    }

    return (
        <Modal {...props} animation={false} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>
            <Modal.Body id="book-activation-dialog-body">
                <LoginSpacing />
                <LoginTitle title="ISKANJE" />
                <LoginTextField id="search" title="Iskalni niz" onChange={handleChange} onKeyPress={handleKeyPress} />
                <LoginSpacing />
                <LoginButton onClick={find}>Išči</LoginButton>
                <LoginSpacing />
                {searchResults && searchResults.map(r => <SearchItem key={r.id} page={r.pageNumber} onPageSelected={pageSelected}/>)}
                {showNoResults && <NoResults />}
                <LoginSpacing />
            </Modal.Body>

            <Snackbar errorMessage={message} updateErrorMessage={updateMessage} />
        </Modal>
    );
}

function SearchItem({ page, onPageSelected }) {
    return (
            <button type="button" className="main-text-color toc-item" onClick={() => onPageSelected(page)}>{page}</button>
    );
}

function NoResults() {
    return (
        <div className="text-center">
            Ni zadetkov
        </div>
    );
}