import React from "react";
import './TableOfContents.css';
import {Modal} from "react-bootstrap";
import LoginSpacing from "../Login/common/LoginSpacing";
import LoginTitle from "../Login/common/LoginTitle";

export default function TableOfContents({ topics, onPageSelected, ...props }) {
    const pageSelected = page => {
        onPageSelected(page);
        props.onHide();
    }

    return (
        <Modal {...props} animation={false} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>
            <Modal.Body id="book-activation-dialog-body">
                <LoginTitle title="Kazalo" />
                <LoginSpacing />
                {topics?.length !== 0 && topics.map(t => <TocItem key={t.id} title={t.name} level={t.ancestors.length} page={t.page} onPageSelected={pageSelected}/>)}
                <LoginSpacing />
            </Modal.Body>
        </Modal>
    );
}

function TocItem({ title, level, page, onPageSelected }) {
    const levelStyle = level === 0 ? "toc-main-section" : (level === 1 ? "toc-section" : "toc-sub-section");

    return (
        <div>
            <button type="button" className={`no-padding no-margin main-text-color toc-item ${levelStyle}`} onClick={() => onPageSelected(page)}>{title}</button>
        </div>
    );
}