import React from "react";
import {useHistory} from "react-router-dom";
import PropTypes from "prop-types";
import './TopNavigation.css';
import '../../resources/styles/Common.css';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Logo from "../Logo/Logo";
import LogoutIcon from "../Home/LogoutIcon";

export default function TopNavigation({ hide = false, onBookActivate, onSettings, onLogout }) {
    const history = useHistory();

    return (
        <Navbar collapseOnSelect expand="lg" variant="dark" className={`fixed-top main-background-color ${hide ? 'navbar-hide' : 'navbar-show'}`} id="navbar">
            <Navbar.Brand href="/" className="logo">
                <Logo />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav" className="main-background-color">
                <Nav className="mr-auto">
                    <TopNavigationLink eventKey="1" onClick={() => history.push("/")}>GRADIVA</TopNavigationLink>
                    <TopNavigationLink eventKey="2" onClick={()=> window.open("https://jutro.si", "_blank")}>TRGOVINA</TopNavigationLink>
                    <TopNavigationLink eventKey="3" onClick={() => onBookActivate()}>AKTIVACIJA</TopNavigationLink>
                    <TopNavigationLink eventKey="4" onClick={() => onSettings()}>NASTAVITVE</TopNavigationLink>
                </Nav>
                <Nav>
                    <TopNavigationLink eventKey="5" onClick={onLogout}>
                        <div id="logout-icon"><LogoutIcon /></div> ODJAVA
                    </TopNavigationLink>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

function TopNavigationLink(props) {
    return (
        <Nav.Link eventKey={props.eventKey} className="nav-item" onClick={props.onClick}><span className="font-weight-bold text-large light-text-color">{props.children}</span></Nav.Link>
    );
}

TopNavigationLink.propTypes = {
    eventKey: PropTypes.string,
}