import React from "react";
import {Pagination} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";

export default function TablePagination({ selectedPage, selectedPageSize, pageSizes = [10, 25], disableNext, onPageSelected, onPageSizeSelected }) {

    function firstClicked() {
        onPageSelected(1);
    }

    function previousClicked() {
        onPageSelected(selectedPage - 1);
    }

    function nextClicked() {
        onPageSelected(selectedPage + 1);
    }

    return (
        <div className="d-flex justify-content-between">
            <PageSizes selected={selectedPageSize || pageSizes[0]} pageSizes={pageSizes} onSelected={onPageSizeSelected} />

            <Pagination className="justify-content-end">
                <Pagination.First disabled={selectedPage === 1} onClick={firstClicked} />
                <Pagination.Prev disabled={selectedPage === 1} onClick={previousClicked} />
                <Pagination.Item active>{selectedPage}</Pagination.Item>
                <Pagination.Next disabled={disableNext} onClick={nextClicked} />
            </Pagination>
        </div>
    )
}

function PageSizes({ selected, pageSizes, onSelected }) {
    return (
        <Dropdown>
            <Dropdown.Toggle variant="outline-light" className="text-dark text-left">{selected}</Dropdown.Toggle>

            <Dropdown.Menu>
                {pageSizes.map((value, index) => {
                    return <Dropdown.Item key={index} onClick={() => onSelected(value)}>{value}</Dropdown.Item>
                })}
            </Dropdown.Menu>
        </Dropdown>
    )
}