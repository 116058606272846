import React, {useEffect, useState} from 'react';

// https://blog.logrocket.com/building-inline-editable-ui-in-react/
export default function Editable({ text, type, placeholder, children, editingMode = false, onBlur = null, ...props }) {
    const [isEditing, setEditing] = useState(editingMode);

    useEffect(() => {
        setEditing(editingMode);
    }, [editingMode]);

    const handleKeyDown = (event, type) => {

    };

    const handleOnBlur = () => {
        setEditing(false);
        if (onBlur) onBlur();
    };

    return (
        <div style={{ display: 'inline' }}>
            {isEditing
                ? (<div style={{ display: 'inline' }} onBlur={handleOnBlur} onKeyDown={e => handleKeyDown(e, type)}>{children}</div>)
                : (<div style={{ display: 'inline' }} onClick={() => setEditing(true)}><span>{text || placeholder || ''}</span></div>)
            }
        </div>
    )
}