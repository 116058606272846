import PropTypes from "prop-types";
import './SearchInput.css';

export default function SearchInput(props) {
    const handleChange = (e) => {
        props.onChange(e);
        if (!e.target.value) props.onEmpty();
    }

    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            props.onClick(e);
        }
    }

    return (
        <div className="search-input">
            <input type="text" onChange={handleChange} onKeyPress={handleKeyPress} />
            <button onClick={props.onClick}>Išči</button>
        </div>
    );
}

SearchInput.propTypes = {
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    onEmpty: PropTypes.func,
}