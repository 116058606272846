import {useState} from 'react';
import {loadUser, storeUser} from "../userUtils";

export default function useUser() {
    const getUser = () => {
        return loadUser();
    }

    const [user, setUser] = useState(getUser());

    const saveUser = (user) => {
        storeUser(user)
        setUser(user);
    }

    return {
        setUser: saveUser,
        user
    }
}