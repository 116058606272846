import React, {useCallback, useEffect, useState} from "react";
import PageTitle from "../common/PageTitle";
import SearchInput from "../common/SearchInput";
import {initialPageData, LoadingTable} from "../common/LoadingTable";
import UserDetails from "./UserDetails";
import useToken from "../../../utils/hooks/useToken";
import {getUsers} from "../../api/AdminApi";
import EnabledIcon from "./EnabledIcon";
import DisabledIcon from "./DisabledIcon";

export default function UsersTable() {
    const { token } = useToken();

    const [userDetails, setUserDetails] = useState(undefined);

    const [users, setUsers] = useState(undefined);
    const [pageData, setPageData] = useState(initialPageData());
    const [search, setSearch] = useState('');
    const [isLoading, setLoading] = useState(true);

    const usersCallback = useCallback(() => {
        setLoading(true);
        getUsers(token, pageData.currentPage, pageData.pageSize)
            .then(data => setUsers(data))
            .finally(() => setLoading(false));
    }, [token, pageData]);

    useEffect(() => {
        usersCallback();
    }, [usersCallback]);

    function rowProvider(user) {
        return (<UserRow key={user.id} rowNo={user.id} user={user} onRowClick={rowClicked} />);
    }

    function handleNavChange(page, pageSize) {
        setPageData(pageData => ({ ...pageData, currentPage: page, pageSize: pageSize }));
    }

    function rowClicked(row) {
        setUserDetails(users.find(user => user.id === row));
    }

    function dialogClosed() {
        usersCallback();
        setUserDetails(undefined);
    }

    function onSearchChange(e) {
        setSearch(e.target.value);
    }

    function onSearchClick(e) {
        setLoading(true);
        getUsers(token, pageData.currentPage, pageData.pageSize, search)
            .then(data => setUsers(data))
            .finally(() => setLoading(false));
    }

    return (
        <>
            <PageTitle title="Uporabniki">
                <SearchInput onChange={onSearchChange} onClick={onSearchClick} onEmpty={usersCallback} />
                &nbsp;
            </PageTitle>

            <LoadingTable
                isLoading={isLoading}
                initialPageSize={pageData.pageSize}
                columns={['#', 'Ime', 'Priimek', 'Email', 'Omogočen']}
                items={users}
                rowProvider={rowProvider}
                onNavChange={handleNavChange} />

            {userDetails !== undefined && <UserDetails show={true} userData={userDetails} onHide={dialogClosed} />}
        </>
    )
}

function UserRow({ rowNo, user, onRowClick }) {
    return (
        <tr onClick={() => onRowClick(rowNo)} style={{ cursor: 'pointer' }}>
            <td>{rowNo}</td>
            <td>{user.firstName}</td>
            <td>{user.lastName}</td>
            <td>{user.email}</td>
            <td>{user.enabled ? <EnabledIcon /> : <DisabledIcon />}</td>
        </tr>
    )
}