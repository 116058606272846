import React, {useState} from "react";
import './CurrentTopicHeader.css';
import '../../resources/styles/Common.css';
import SearchIcon from "./SearchIcon";
import Search from "./Search";
import Editable from "../common/Editable";
import TableOfContents from "./TableOfContents";

const BUTTONS_MIN_WIDTH = 156;

export default function CurrentTopicHeader({ bookId, topics, contentOffset, contentWidth, title, subtitle, currentPage, maxPage, onPageSelected, navbarHidden = false }) {
    const [showTableOfContents, setShowTableOfContents] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [showPageSelector, setShowPageSelector] = useState(false);
    const [page, setPage] = useState(currentPage);

    const offsetClassName = navbarHidden ? 'current-topic-no-offset' : 'current-topic-offset';
    const isDesktop = contentWidth > 768;

    const subtitleOffset = isDesktop ? contentOffset + contentWidth * 0.08 : 16;
    const subtitleWidth = (isDesktop ? 0.585 : 0.75) * contentWidth;
    const buttonsWidth = contentWidth - subtitleWidth - subtitleOffset;
    const subtitleMinWidth = isDesktop ? 0 : contentWidth - BUTTONS_MIN_WIDTH - subtitleOffset;

    const gridTemplateColumns = buttonsWidth < BUTTONS_MIN_WIDTH && !isDesktop ? `${subtitleOffset}px auto ${BUTTONS_MIN_WIDTH}px` : `${subtitleOffset}px ${subtitleWidth}px auto`;

    function hideDialogs() {
        setShowTableOfContents(false);
        setShowSearch(false);
        setShowPageSelector(false);
    }

    function showTocDialog() {
        hideDialogs();
        setShowTableOfContents(true);
    }

    function showSearchDialog() {
        hideDialogs();
        setShowSearch(true);
    }

    function showPageSelectorField() {
        hideDialogs();
        setShowPageSelector(true);
    }

    const pageSelected = () => {
        onPageSelected(page);
    }

    const handleChange = e => {
        const newPage = e.target.value;

        if (newPage && newPage < 1) return;
        if (newPage && maxPage && newPage > maxPage) return;

        setPage(e.target.value);
    }

    const handleKeyDown = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            e.target.blur();

            pageSelected();
        }
    }

    return (
        <>
            <div className={`current-topic-container vw-100 ${offsetClassName}`}>
                <div className="current-topic" style={{ gridTemplateColumns: `${gridTemplateColumns}` }}>
                    <span>&nbsp;</span>
                    <span className="text-larger current-topic-text" style={{ minWidth: `${subtitleMinWidth}px`, paddingRight: '5px' }}>
                        <TopicButton className="current-topic-overflow w-100" onClick={showTocDialog}>{subtitle?.replaceAll('\n', ' ') || ''}</TopicButton>
                    </span>
                    <span className="text-larger current-topic-text current-topic-title">{title?.replaceAll('\n', ' ') || ''}</span>
                    <span className="text-larger current-topic-text current-topic-buttons" style={{ minWidth: `${buttonsWidth}px` }}>
                        <TopicButton onClick={showSearchDialog}><SearchIcon /></TopicButton>
                        &nbsp;&nbsp;
                        <TopicButton onClick={showPageSelectorField}>
                            <Editable text={`${currentPage} stran`} type="input" editingMode={showPageSelector} onBlur={() => setShowPageSelector(false)}>
                                <input autoFocus className="main-border-color text-large" type="number" value={page.toString()} autoComplete="off" style={{ height: '2rem', width: '4rem' }} onChange={handleChange} onKeyDown={handleKeyDown} />
                            </Editable>
                        </TopicButton>
                    </span>
                </div>
            </div>

            {showTableOfContents && <TableOfContents topics={topics} show={showTableOfContents} onHide={hideDialogs} onPageSelected={onPageSelected} />}
            {showSearch && <Search bookId={bookId} show={showSearch} onHide={hideDialogs} onPageSelected={onPageSelected} />}
        </>
    );
}

function TopicButton({ className = '', onClick, ...props }) {
    return (
        <div className={className} style={{ display: "inline-block", cursor: "pointer" }} onClick={onClick}>
            {props.children}
        </div>
    );
}