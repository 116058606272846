import React, {useCallback, useEffect, useState} from "react";
import PageTitle from "../common/PageTitle";
import {initialPageData, LoadingTable} from "../common/LoadingTable";
import SettingDetails from "./SettingDetails";
import useToken from "../../../utils/hooks/useToken";
import {getSettings} from "../../api/AdminApi";

export default function SettingsTable() {
    const { token } = useToken();

    const [settingDetails, setSettingDetails] = useState(undefined);

    const [settings, setSettings] = useState(undefined);
    const [pageData, setPageData] = useState(initialPageData());
    const [isLoading, setLoading] = useState(true);

    const settingsCallback = useCallback(() => {
        setLoading(true);
        getSettings(token, pageData.currentPage, pageData.pageSize)
            .then(data => setSettings(data))
            .finally(() => setLoading(false));
    }, [token, pageData]);

    useEffect(() => {
        settingsCallback();
    }, [settingsCallback]);

    function rowProvider(setting, index) {
        return (<SettingRow key={index + 1} rowNo={index + 1} onRowClick={rowClicked} {...setting} />);
    }

    function handleNavChange(page, pageSize) {
        setPageData(pageData => ({ ...pageData, currentPage: page, pageSize: pageSize }));
    }

    function rowClicked(setting) {
        setSettingDetails(settings.find(s => s.setting === setting));
    }

    function settingDialogClosed() {
        settingsCallback();
        setSettingDetails(undefined);
    }

    return (
        <>
            <PageTitle title="Nastavitve" />

            <LoadingTable
                isLoading={isLoading}
                initialPageSize={pageData.pageSize}
                columns={['#', 'Naziv', 'Vrednost']}
                items={settings}
                rowProvider={rowProvider}
                onNavChange={handleNavChange} />

            {settingDetails !== undefined && <SettingDetails show={true} settingData={settingDetails} onHide={settingDialogClosed} />}
        </>
    )
}

function SettingRow({ rowNo, setting, value, isSensitive, onRowClick }) {
    return (
        <tr onClick={() => onRowClick(setting)} style={{ cursor: 'pointer' }}>
            <td>{rowNo}</td>
            <td>{setting}</td>
            <td>{isSensitive ? '***' : value}</td>
        </tr>
    )
}