export function downloadCSV(array, fileName = "data.csv") {
    let rows = [] // rows of values
    let csvContent = "data:text/csv;charset=utf-8,";

    // header
    let header = []
    for (const key in array[0]) {
        header.push(key)
    }
    rows.push(header)

    // other rows
    array.forEach(element => {
        let singleRow = []
        for (const key in element) {
            if (Object.hasOwnProperty.call(element, key)) {
                let value = element[key];
                singleRow.push(value)
            }
        }
        rows.push(singleRow)
    });
    rows.forEach(function(rowArray) {
        let row = rowArray.join(",");
        csvContent += row + "\r\n";
    });

    let url = csvContent;

    let downloadLink = document.createElement('a');
    downloadLink.setAttribute('download', `${fileName}`);
    downloadLink.setAttribute('href', url);
    downloadLink.click();
}