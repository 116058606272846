import React, {useCallback, useEffect, useState} from "react";
import '../../../resources/styles/Common.css';
import {Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {LabeledDateInput, LabeledInput, LabeledMultilineInput} from "../common/LabeledInput";
import Snackbar from "../../../components/common/Snackbar";
import useToken from "../../../utils/hooks/useToken";
import {addBookTag, createNewBook, deleteBookTag, getTags, updateBook, uploadCoverImage, uploadPdf} from "../../api/AdminApi";
import {isoDateString, todayString} from "../../../utils/dateUtils";
import {limit} from "../../../utils/numberUtils";

const Files = {
    COVER_IMAGE: 'frontPage',
    BOOK_PDF: 'bookPdf'
}

const EmptyBook = {
    name: '',
    description: '',
    tag: null,
    frontPage: 'image.url',
    backPage: 'image.url',
    dateIssued: null,
    shopId: null,
    shopUrl: null,
    validFrom: null,
    validTo: null
}

const EmptyTag = {
    name: '',
    order: 1
}

export default function BookDetails({ bookData, ...props }) {
    const { token } = useToken();

    const [message, updateMessage] = useState(null);

    const [tags, setTags] = useState(undefined);
    const [initialTags] = useState(bookData?.tags);
    const [book, setBook] = useState(prepareData(bookData));
    const [files, setFiles] = useState({});
    const [isNew] = useState(bookData === undefined);

    const tagsCallback = useCallback(() => {
        getTags(token).then(data => setTags(data));
    }, [token]);

    useEffect(() => {
        tagsCallback();
    }, [tagsCallback]);

    function onTagUpdated(event) {
        const tagName = event.target.value;
        let tag = tags.find(tag => tag.name === tagName);
        if (!tag) {
            tag = {
                ...Object.assign({}, EmptyTag),
                name: tagName
            };
        }
        update('tag', tag);
    }

    function handleAdminTagsChange(selected) {
        let updatedTags = [];
        selected.forEach(s => updatedTags.push(tags.find(t => t.name === s)));
        setBook(book => ({ ...book, tags: updatedTags }));
    }

    function handleFileChanged(event) {
        const file = event.target.files[0];

        if (file.size > 50 * 1024 * 1024) {
            updateMessage('Datoteka ne sme biti večja od 50MB');
            event.target.value = '';
            return;
        }

        updateUploadFiles(event.target.id, file);
    }

    function update(key, value) {
        let updated = book;
        updated[key] = value;
        setBook(book => ({ ...book, ...updated }));
    }

    function updateUploadFiles(key, value) {
        let updated = files;
        updated[key] = value;
        setFiles(uploadFiles => ({ ...uploadFiles, ...updated }));
        console.log(updated);
    }

    async function addClicked() {
        if (!validate()) return;

        const dataToSave = prepareData(book);
        let bookId = book.id;

        if (isNew) {
            const result = await createNewBook(token, dataToSave);
            bookId = result.data.id;
            const allTags = [dataToSave.tag].concat(dataToSave.tags);
            await Promise.all(allTags.map(tag => addBookTag(token, bookId, tag)));
        } else {
            await updateBook(token, dataToSave);
            await updateTags(book.id, dataToSave);
        }

        await uploadFiles(bookId);

        props.onHide();
    }

    function validate() {
        let errorMessage = '';

        if (!book.name) errorMessage = 'Vnesi naslov';
        else if (book.name.length < 3) errorMessage = 'Naslov mora imeti vsaj 3 znake';
        else if (!book.tag) errorMessage = 'Izberi skupino';
        else if (!book.description) errorMessage = 'Vnesi opis';
        else if (book.description.length < 3) errorMessage = 'Opis mora imeti vsaj 3 znake';

        if (errorMessage.length > 0) {
            updateMessage(errorMessage);
            return false;
        }

        return true;
    }

    function prepareData(bookData) {
        let data = bookData || EmptyBook;

        const tag = data.tag || (data.tags ? data.tags[0] : null);

        data = {
            ...data,
            tag:  tag,
            dateIssued: isoDateString(data.dateIssued) || todayString(),
            validFrom: isoDateString(data.validFrom),
            validTo: isoDateString(data.validTo),
        };

        Object.keys(data).forEach((k) => data[k] == null && delete data[k]); // Let's remove `null` values.

        return data;
    }

    async function updateTags(bookId, dataToSave) {
        const allTags = [dataToSave.tag].concat(dataToSave.tags);

        // Delete all tags that are in `initialTags` and not in `allTags`.
        const tagsToDelete = [];
        initialTags.forEach(i => {
            if (!allTags.find(a => a.name === i.name)) tagsToDelete.push(i)
        });
        await Promise.all(tagsToDelete.map(tag => deleteBookTag(token, bookId, tag)));

        // Add all tags that are in `allTags` and not in `initialTags`.
        const tagsToAdd = [];
        allTags.forEach(a => {
            if (!initialTags.find(i => i.name === a.name)) tagsToAdd.push(a);
        });
        await Promise.all(tagsToAdd.map(tag => addBookTag(token, bookId, tag)));
    }

    function uploadFiles(bookId) {
        return Promise.all(Object.keys(files).map(key => {
            const file = files[key];
            const isImage = file.type.match('image.*');
            const data = new FormData();
            data.append(key, file);
            return isImage ? uploadCoverImage(token, bookId, data) : uploadPdf(token, bookId, data);
        }));
    }

    return (
        <Modal {...props} animation={false} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {book.name || 'Nova knjiga'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LabeledInput label='Naslov' fieldId='bookName' value={book.name} onChange={e => update('name', e.target.value)} /><br />
                <TitledComboBox label='Skupina knjig' value={book.tag?.name} items={tags?.filter(t => t.order !== -1)} onChange={onTagUpdated} /><br />
                <MultiSelectInput label='Skupine za admin' fieldId='adminTags' items={tags?.filter(t => t.order === -1)} selected={book?.tags?.filter(t => t.order === -1)} onSelectionChange={handleAdminTagsChange} /><br />
                <LabeledMultilineInput label='Opis' fieldId='description' value={book.description} onChange={e => update('description', e.target.value)} /><br />
                <FileInput label='Naslovna slika' fieldId={Files.COVER_IMAGE} onChange={handleFileChanged} /><br />
                <FileInput label='PDF učbenika' fieldId={Files.BOOK_PDF} onChange={handleFileChanged} /><br />
                <LabeledInput label='Koda v trgovini' fieldId='shopId' value={book.shopId} onChange={e => update('shopId', e.target.value)} /><br />
                <LabeledInput label='Povezava do trgovine' fieldId='shopUrl' value={book.shopUrl} onChange={e => update('shopUrl', e.target.value)} /><br />
                <div className="d-flex">
                    <LabeledDateInput label='Prikazano od' fieldId='validFrom' value={book.validFrom} onChange={e => update('validFrom', e.target.value)} />
                    <LabeledDateInput label='   do' fieldId='validTo' value={book.validTo} onChange={e => update('validTo', e.target.value)} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={props.onHide}>Prekliči</Button>
                <Button onClick={addClicked}>{isNew ? 'Dodaj' : 'Shrani'}</Button>
            </Modal.Footer>

            <Snackbar errorMessage={message} updateErrorMessage={updateMessage} />
        </Modal>
    );
}

function FileInput({ label, fieldId, onChange }) {
    const finalLabel = label.replace(/ /g, "\u00a0");

    return (
        <div className="d-flex">
            <label htmlFor={fieldId} className="vertical-center no-margin">{finalLabel}:&nbsp;&nbsp;&nbsp;</label>
            <input className="full-width" type="file" id={fieldId} onChange={onChange} />
        </div>
    );
}

function TitledComboBox({ label, fieldId, value, items, onChange }) {
    const finalLabel = label.replace(/ /g, "\u00a0");
    const listId = `${fieldId}_items`;

    return (
        <div className="d-flex">
            <label htmlFor={fieldId} className="vertical-center no-margin">{finalLabel}:&nbsp;&nbsp;&nbsp;</label>
            <input type="text" className="form-control" name={fieldId} value={value || ''} list={listId} onChange={onChange} autoComplete="off" />
            <datalist id={listId}>
                {items && items.map(i => (<option key={i.id} value={i.name} />) )}
            </datalist>
        </div>
    );
}

function MultiSelectInput({ label, fieldId, items, selected, onSelectionChange }) {
    const finalLabel = label.replace(/ /g, "\u00a0");

    function handleChange(event) {
        const options = event.target.options;
        const value = [];

        for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }

        onSelectionChange(value);
    }

    return (
        <div className="d-flex">
            <label htmlFor={fieldId}>{finalLabel}:&nbsp;&nbsp;&nbsp;</label>
            <select name={fieldId} id={fieldId} multiple size={limit(items?.length, 3, 6)} value={selected?.map(s => s.name)} onChange={handleChange}>
                {items && items.map(i => (<option key={i.id} value={i.name}>{i.name}</option>))}
            </select>
        </div>
    );
}