import React, {useCallback, useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import PageTitle from "../common/PageTitle";
import SearchInput from "../common/SearchInput";
import {initialPageData, LoadingTable} from "../common/LoadingTable";
import ActivationCodeNew from "./ActivationCodeNew";
import ActivationCodeDetails from "./ActivationCodeDetails";
import useToken from "../../../utils/hooks/useToken";
import {getActivationCodes} from "../../api/AdminApi";
import {formattedDate} from "../../../utils/dateUtils";
import ActivationCodesSchoolNew from "./ActivationCodesSchoolNew";

export default function ActivationCodesTable() {
    const { token } = useToken();

    const [codeDetails, setCodeDetails] = useState(undefined);
    const [showNewCodeDialog, setShowNewCodeDialog] = useState(false);
    const [showNewSchoolCodesDialog, setShowNewSchoolCodesDialog] = useState(false);

    const [codes, setCodes] = useState(undefined);
    const [pageData, setPageData] = useState(initialPageData());
    const [search, setSearch] = useState('');
    const [isLoading, setLoading] = useState(true);

    const activationCodesCallback = useCallback(() => {
        setLoading(true);
        getActivationCodes(token, pageData.currentPage, pageData.pageSize)
            .then(data => setCodes(data))
            .finally(() => setLoading(false));
    }, [token, pageData]);

    useEffect(() => {
        activationCodesCallback();
    }, [activationCodesCallback]);

    function rowProvider(code) {
        return (<ActivationCodeRow key={code.id} rowNo={code.id} onRowClick={rowClicked} {...code} />);
    }

    function handleNavChange(page, pageSize) {
        setPageData(pageData => ({ ...pageData, currentPage: page, pageSize: pageSize }));
    }

    function rowClicked(row) {
        setCodeDetails(codes.find(code => code.id === row));
    }

    function addCodeButtonClicked() {
        setShowNewCodeDialog(true);
    }

    function addSchoolCodesButtonClicked() {
        setShowNewSchoolCodesDialog(true);
    }

    function addCodeDialogClosed() {
        activationCodesCallback();
        setShowNewCodeDialog(false);
        setShowNewSchoolCodesDialog(false);
    }

    function onSearchChange(e) {
        setSearch(e.target.value);
    }

    function onSearchClick(e) {
        setLoading(true);
        getActivationCodes(token, pageData.currentPage, pageData.pageSize, search)
            .then(data => setCodes(data))
            .finally(() => setLoading(false));
    }

    return (
        <>
            <PageTitle title="Aktivacijske kode">
                <SearchInput onChange={onSearchChange} onClick={onSearchClick} onEmpty={activationCodesCallback} />
                <div>
                    <Button onClick={addSchoolCodesButtonClicked}>Dodaj kode za šolo</Button>
                    &nbsp;&nbsp;
                    <Button onClick={addCodeButtonClicked}>Dodaj kodo</Button>
                </div>
            </PageTitle>

            <LoadingTable
                isLoading={isLoading}
                initialPageSize={pageData.pageSize}
                columns={['#', 'Uporabnik', 'Šola', 'Knjiga oz. sekcija knjig', 'Aktivirano dne', 'Veljavno do']}
                items={codes}
                rowProvider={rowProvider}
                onNavChange={handleNavChange} />

            {codeDetails !== undefined && <ActivationCodeDetails show={true} codeData={codeDetails} onHide={() => setCodeDetails(undefined)} />}

            {showNewCodeDialog && <ActivationCodeNew show={showNewCodeDialog} onHide={addCodeDialogClosed} />}

            {showNewSchoolCodesDialog && <ActivationCodesSchoolNew show={showNewSchoolCodesDialog} onHide={addCodeDialogClosed} />}
        </>
    )
}

function ActivationCodeRow({ rowNo, usedBy, school, book, tag, used, validTo, onRowClick }) {
    const fullName = () => {
        const firstName = usedBy ? usedBy.firstName : '';
        const lastName = usedBy ? usedBy.lastName : '';
        return `${firstName} ${lastName}`;
    }

    const bookOrTagName = () => {
        return book?.name ?? `Sekcija knjig: ${tag?.name}`;
    }

    return (
        <tr onClick={() => onRowClick(rowNo)} style={{ cursor: 'pointer' }}>
            <td>{rowNo}</td>
            <td>{fullName()}</td>
            <td>{school?.name || ''}</td>
            <td>{bookOrTagName()}</td>
            <td>{formattedDate(used)}</td>
            <td>{formattedDate(validTo)}</td>
        </tr>
    )
}