import React, {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import TitledDropdown from "../common/TitledDropdown";
import {LabeledInput, LabeledIntegerInput} from "../common/LabeledInput";
import Snackbar from "../../../components/common/Snackbar";
import useToken from "../../../utils/hooks/useToken";
import {createNewBookTopic, deleteBookTopic, updateBookTopic} from "../../api/AdminApi";
import {NotFoundError} from "../../../utils/errors";

const EmptyTopic = {
    topicId: null,
    name:  '',
    index: '',
    'page': null,
    order: null,
}

export default function BookTopic({ topicData, topics, bookData, ...props }) {
    const { token } = useToken();

    const [message, updateMessage] = useState(null);

    const [topic, setTopic] = useState(EmptyTopic);
    const [isNew] = useState(topicData === undefined);
    const [selectedParentTopic, setSelectedParentTopic] = useState(undefined);

    useEffect(() => {
        if (!topicData) return;
        setTopic({ ...topicData, topicId: topicData?.parentTopicId });
        const parentTopic = topics?.find(t => t.id === topicData?.parentTopicId);
        setSelectedParentTopic(parentTopic);
    }, [topicData, topics]);

    function update(key, value) {
        let updated = topic;
        updated[key] = value;
        setTopic(topic => ({ ...topic, ...updated }));
    }

    function parentTopicSelected(eventKey, event) {
        const parentTopic = topics.find(t => t.id == eventKey);
        setSelectedParentTopic(parentTopic);
        update('topicId', parentTopic?.id);
    }

    async function addClicked() {
        if (!validate()) return;

        if (isNew) {
            await createNewBookTopic(token, bookData, sanitizeData(topic));
        } else {
            await updateBookTopic(token, bookData, sanitizeData(topic));
        }

        props.onHide();
    }

    async function deleteClicked() {
        if (topic?.id) {
            const response = await deleteBookTopic(token, bookData, topic);
            if (response instanceof NotFoundError) {
                updateMessage('Poglavja ni možno pobrisati, ker ima gnezdena poglavja');
                return;
            }
        }
        props.onHide();
    }

    function validate() {
        let errorMessage = '';

        if (!topic.name) errorMessage = 'Vnesi poglavje';
        else if (topic.name.length < 3) errorMessage = 'Poglavje mora imeti vsaj 3 znake';
        else if (topic.page === '') errorMessage = 'Vnesi stran';
        else if (topic.order === '') errorMessage = 'Vnesi vrstni red';
        else if (!Number.isInteger(parseInt(topic.page))) errorMessage = 'Stran mora biti cela številka';
        else if (!Number.isSafeInteger(parseInt(topic.page))) errorMessage = 'Stran vsebuje preveliko številko';
        else if (!Number.isInteger(parseInt(topic.order))) errorMessage = 'Vrstni red mora biti cela številka';
        else if (!Number.isSafeInteger(parseInt(topic.order))) errorMessage = 'Vrstni red vsebuje preveliko številko';
        else if (topic.page < 1) errorMessage = 'Stran mora biti večja ali enaka 1';
        else if (topic.order < 0) errorMessage = 'Vrstni red mora biti večji ali enak 0';

        if (errorMessage.length > 0) {
            updateMessage(errorMessage);
            return false;
        }

        return true;
    }

    function sanitizeData(topic) {
        const data = topic;
        if (!data.topicId) delete data.topicId;
        delete data.ancestors;
        delete data.parentTopicId;
        return data;
    }

    return (
        <Modal {...props} animation={false} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Kazalo - {bookData?.name}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TitledDropdown label='Gnezdi pod' items={topics} selectedTitle={selectedParentTopic?.name} defaultTitle='Izberi poglavje' onSelect={parentTopicSelected} />
                <LabeledInput label='Naziv' fieldId='topicName' value={topic.name} onChange={e => update('name', e.target.value)} /><br />
                <LabeledIntegerInput label='Stran' fieldId='topicPage' value={topic.page} showNullValues={true} onChange={e => update('page', e.target.value)} /><br />
                <LabeledIntegerInput label='Vrstni red' fieldId='topicOrder' value={topic.order} showNullValues={true} onChange={e => update('order', e.target.value)} /><br />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" className="mr-auto" onClick={deleteClicked}>Briši poglavje</Button>
                <Button variant="outline-primary" onClick={props.onHide}>Prekliči</Button>
                <Button onClick={addClicked}>{isNew ? 'Dodaj' : 'Shrani'}</Button>
            </Modal.Footer>

            <Snackbar errorMessage={message} updateErrorMessage={updateMessage} />
        </Modal>
    );
}