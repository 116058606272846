import React, {useCallback, useEffect, useState} from 'react';
import {useParams, withRouter} from "react-router-dom";
import LoginTitle from "../common/LoginTitle";
import LoginTextField from "../common/LoginTextField";
import LoginSpacing from "../common/LoginSpacing";
import {LoginAuxiliaryButton, LoginButton} from "../common/LoginButtons";
import {loginUser, newPassword} from "../../../api/AuthApi";
import {redirectToHome, redirectToLogin} from "../../../utils/navUtils";

function NewPassword(props) {
    let { code } = useParams();

    const [state , setState] = useState({
        password: '',
        confirmPassword: '',
    })

    const [errorMessage, setErrorMessage] = useState('');

    const handleError = useCallback((message) => {
        setErrorMessage(message);
    }, []);

    useEffect(() => {
        if (errorMessage) {
            handleError(errorMessage);
            setErrorMessage(null);
        }
    }, [errorMessage, handleError]);

    const handleChange = (e) => {
        const {id , value} = e.target
        setState(prevState => ({
            ...prevState,
            [id] : value
        }))
    }

    const handleSubmit = async e => {
        e.preventDefault();
        await sendDetailsToServer()
    }

    const sendDetailsToServer = async () => {
        if (!isValidForm()) return;

        setErrorMessage(null);

        const payload = {
            "newPassword": state.password,
            "confirmation": code,
        }

        const response = await newPassword(payload);

        if (response.type === 'error') {
            const error = response.errors && response.errors.length ? response.errors[0].msg : response.message;
            setErrorMessage(`Napaka pri ponastavitvi gesla: ${error}`);
        } else {
            const credentials = { email: response.email, password: state.password }
            const user = await loginUser(credentials);
            props.setUser(user);
            redirectToHome(props.history);
        }
    }

    const isValidForm = () => {
        let error = '';

        if (!state.password) error = 'Vnesti morate geslo';
        else if (state.password.includes(' ')) error = 'Geslo ne sme vsebovati presledkov';
        else if (state.password.length < 6) error = 'Geslo mora vsebovati vsaj 6 znakov';
        else if (state.password.length > 20) error = 'Geslo ne sme biti daljše od 20 znakov';
        else if (state.password !== state.confirmPassword) error = 'Gesli se ne ujemata';

        if (error.length) setErrorMessage(error);

        return error.length === 0;
    }

    return (
        <>
            <LoginTitle title="Novo geslo" />
            <form className="registration-form" onSubmit={handleSubmit}>
                <LoginTextField id="password" type="password" title="Geslo" onChange={handleChange} />
                <LoginSpacing />
                <LoginTextField id="confirmPassword" type="password" title="Ponovi geslo" onChange={handleChange} />
                <LoginSpacing />
                <LoginButton errorMessage={errorMessage}>Nastavi geslo</LoginButton>
                <LoginSpacing />
                <LoginAuxiliaryButton onClick={() => redirectToLogin(props.history)}>Nazaj na prijavo</LoginAuxiliaryButton>
            </form>
        </>
    )
}

export default withRouter(NewPassword);