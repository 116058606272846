import {apiGetCall, authorizedApiDeleteCall, authorizedApiPostCall, authorizedApiPutCall} from "../../api/common";
import * as Config from "../../config";

export async function getBooks(accessToken, page, limit) {
    return apiGetCall(accessToken, 'books', page, limit);
}

export async function getUsers(accessToken, page, limit, search = null) {
    return apiGetCallWithSearch(accessToken, 'users', page, limit, search);
}

export async function getActivationCodes(accessToken, page, limit, search = null) {
    return apiGetCallWithSearch(accessToken, 'code', page, limit, search);
}

export async function getUserBooks(accessToken, userId, page, limit) {
    return apiGetCall(accessToken, `users/${userId}/books`, page, limit);
}

export async function getActivationCode(accessToken, codeId) {
    return apiGetCall(accessToken, `code/${codeId}`);
}

export async function getTags(accessToken, page, limit) {
    return apiGetCall(accessToken, 'tags', page, limit);
}

export async function getTagBooks(accessToken, tagId, page, limit) {
    return apiGetCall(accessToken, `tags/${tagId}/books`, page, limit);
}

export async function getSchools(accessToken, page, limit) {
    return apiGetCall(accessToken, 'schools', page, limit);
}

export async function getSchoolCodes(accessToken, schoolId, page, limit) {
    return apiGetCall(accessToken, `schools/${schoolId}/codes`, page, limit);
}

export async function getPasswordResetRequests(accessToken, page, limit) {
    return apiGetCall(accessToken, 'users/passwordRequests', page, limit);
}

export async function getSettings(accessToken, page, limit) {
    return apiGetCall(accessToken, 'settings', page, limit);
}

export async function createNewBook(accessToken, book) {
    return authorizedApiPostCall(accessToken, 'books', book);
}

export async function updateBook(accessToken, book) {
    return authorizedApiPutCall(accessToken, `books/${book.id}`, book);
}

export async function createNewBookTopic(accessToken, book, topic) {
    return authorizedApiPostCall(accessToken, `books/${book.id}/topics`, topic);
}

export async function updateBookTopic(accessToken, book, topic) {
    return authorizedApiPutCall(accessToken, `books/${book.id}/topics/${topic.id}`, topic);
}

export async function deleteBookTopic(accessToken, book, topic) {
    return authorizedApiDeleteCall(accessToken, `books/${book.id}/topics/${topic.id}`);
}

export async function enableUser(accessToken, userId, enable) {
    return authorizedApiPostCall(accessToken, `users/${userId}/enabled`, { enabled: enable });
}

export async function addBookTag(accessToken, bookId, tag) {
    return authorizedApiPostCall(accessToken, `books/${bookId}/tags`, tag);
}

export async function deleteBookTag(accessToken, bookId, tag) {
    return authorizedApiDeleteCall(accessToken, `books/${bookId}/tags/${tag.id}`);
}

export async function createNewActivationCode(accessToken, bookId) {
    return authorizedApiPostCall(accessToken, 'code/create', { bookId: bookId });
}

export async function createNewSchoolActivationCodes(accessToken, schoolId, tagId, count) {
    return authorizedApiPostCall(accessToken, 'code/create', { schoolId: schoolId, tagId: tagId, count: count });
}

export async function createNewTag(accessToken, tag) {
    return authorizedApiPostCall(accessToken, 'tags', tag);
}

export async function updateTag(accessToken, tag) {
    return authorizedApiPutCall(accessToken, `tags/${tag.id}`, tag);
}

export async function createNewSchool(accessToken, school) {
    return authorizedApiPostCall(accessToken, 'schools', school);
}

export async function updateSchool(accessToken, school) {
    return authorizedApiPutCall(accessToken, `schools/${school.id}`, school);
}

export async function uploadPdf(accessToken, bookId, data) {
    return uploadFile(accessToken, `upload/${bookId}`, data);
}

export async function uploadCoverImage(accessToken, bookId, data) {
    return uploadFile(accessToken, `upload/${bookId}/frontPage`, data);
}

export async function updateSetting(accessToken, setting) {
    return authorizedApiPutCall(accessToken, `settings/${setting.setting}`, { value: setting.value });
}

export async function uploadFile(accessToken, endpoint, data) {
    fetch(`${Config.API_URL}/${endpoint}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: data
    }).then((response) =>  {
        return response.json();
    }).catch(error => {
        return error;
    });
}

function apiGetCallWithSearch(accessToken, endpoint, page, limit, search) {
    const params = search ? [ `search=${search}` ] : [];
    return apiGetCall(accessToken, endpoint, page, limit, params);
}