import React, {useCallback, useEffect, useState} from "react";
import './ActivationCodeDetails.css';
import {Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import TitledDropdown from "../common/TitledDropdown";
import useToken from "../../../utils/hooks/useToken";
import {createNewActivationCode, getBooks} from "../../api/AdminApi";

export default function ActivationCodeNew(props) {
    const { token } = useToken();

    const [books, setBooks] = useState(undefined);
    const [selectedBook, setSelectedBook] = useState(undefined);

    const booksCallback = useCallback(() => {
        getBooks(token).then(data => setBooks(data));
    }, [token]);

    useEffect(() => {
        booksCallback();
    }, [booksCallback]);

    useEffect(() => {
        if (!props.show) setSelectedBook(undefined);
    }, [props.show]);

    function bookSelected(eventKey, event) {
        setSelectedBook(books.find(book => book.id == eventKey))
    }

    async function addClicked() {
        if (!selectedBook?.id) return;
        await createNewActivationCode(token, selectedBook.id);
        props.onHide();
    }

    return (
        <Modal {...props} animation={false} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Nova aktivacijska koda
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TitledDropdown label='Knjiga' items={books} selectedTitle={selectedBook?.name} defaultTitle='Izberi knjigo' onSelect={bookSelected} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={props.onHide}>Prekliči</Button>
                <Button onClick={addClicked}>Dodaj</Button>
            </Modal.Footer>
        </Modal>
    );
}