import {Route, Redirect} from "react-router-dom";
import {LOGIN_PATH} from "../../utils/navUtils";
import useUser from "../../utils/hooks/useUser";
import {useEffect} from "react";
import useToken from "../../utils/hooks/useToken";

/**
 * -------
 * TO READ
 * -------
 * - Private routes:
 *   https://betterprogramming.pub/building-basic-react-authentication-e20a574d5e71  <-  TODO: maybe update to this implementation (it appears cleaner)
 *   https://mistryakshar54.medium.com/route-protection-in-react-using-react-router-dom-with-a-simple-example-53dc80460b0b
 *   https://reactrouter.com/web/example/auth-workflow
 * - useAuth hook:
 *   https://usehooks.com/useAuth/
 *
 * -------
 * Sources
 * -------
 * - Private routes:
 *   https://coderwhodreams.com/blog/creating-private-routes-and-handling-session-in-react-js/
 */

export default function AdminRoute({ children, ...rest}) {
    const { user } = useUser();
    const { setToken } = useToken();

    useEffect(() => {
        if (user?.isAdmin !== true) {
            setToken(null);
        }
    }, [user, setToken]);

    return (
        <Route {...rest} render={() => user?.isAdmin && user?.token ? (children) : (<Redirect to={{ pathname: LOGIN_PATH }}/>)}>
        </Route>
    );
}