export const HOME_PATH = '/';
export const SETTING_PATH = '/nastavitve';
export const BOOK_PATH = '/u([0-9]+)-*';
export const LOGIN_PATH = '/prijava';
export const LOST_PASSWORD_PATH = '/pozabljeno-geslo';
export const NEW_PASSWORD_PATH = '/novo-geslo/:code';
export const ACTIVATION_PATH = '/aktivacija';
export const ACTIVATION_CODE_PATH = '/aktivacija/:code';
export const REGISTRATION_PATH = '/registracija';

export const ADMIN_PATH = '/admin';
export const ADMIN_BOOKS_PATH = `${ADMIN_PATH}/books`;
export const ADMIN_CODES_PATH = `${ADMIN_PATH}/codes`;
export const ADMIN_USERS_PATH = `${ADMIN_PATH}/users`;
export const ADMIN_PASSWORD_RESET_REQUESTS_PATH = `${ADMIN_PATH}/passwordResetRequests`;
export const ADMIN_BOOK_TAGS_PATH = `${ADMIN_PATH}/bookTags`;
export const ADMIN_SCHOOLS_PATH = `${ADMIN_PATH}/schools`;
export const ADMIN_SETTINGS_PATH = `${ADMIN_PATH}/settings`;

export const redirectTo = (history, pathname, state = null) => history.push(pathname, state);

export const redirectToLogin = (history, state = null) => redirectTo(history, LOGIN_PATH, state);
export const redirectToLostPassword = (history) => redirectTo(history, LOST_PASSWORD_PATH);
export const redirectToActivation = (history) => redirectTo(history, ACTIVATION_PATH);
export const redirectToRegistration = (history, state = null) => redirectTo(history, REGISTRATION_PATH, state);
export const redirectToHome = (history) => redirectTo(history, HOME_PATH);