import React from "react";
import './AdminPage.css';
import {useLocation, useHistory} from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {ADMIN_BOOK_TAGS_PATH, ADMIN_CODES_PATH, ADMIN_PASSWORD_RESET_REQUESTS_PATH, ADMIN_PATH, ADMIN_SCHOOLS_PATH, ADMIN_SETTINGS_PATH, ADMIN_USERS_PATH, redirectToLogin} from "../../utils/navUtils";
import useToken from "../../utils/hooks/useToken";
import AdminNavBar from "../components/AdminNavBar/AdminNavBar";
import BooksTable from "../components/Books/BooksTable";
import UsersTable from "../components/Users/UsersTable";
import ActivationCodesTable from "../components/ActivationCodes/ActivationCodesTable";
import BookTagsTable from "../components/BookTags/BookTagsTable";
import SchoolsTable from "../components/Schools/SchoolsTable";
import PasswordResetRequestsTable from "../components/PasswordResetRequests/PasswordResetRequestsTable";
import SettingsTable from "../components/Settings/SettingsTable";

export default function AdminPage(props) {
    const { setToken } = useToken();
    const location = useLocation()
    const history = useHistory()

    const handleLogout = () => {
        setToken(null);
        redirectToLogin(history);
    }

    return (
        <Container fluid className="px-0">
            <Row noGutters>
                <Col>
                    <AdminNavBar onLogout={handleLogout} />
                </Col>
            </Row>
            <Row noGutters className="admin-main-row">
                <Col md={{span: '10', offset: '1'}}>
                    <AdminPageRouter path={location.pathname} defaultRoute={(<></>)}>
                        <BooksTable path={ADMIN_PATH} />
                        <ActivationCodesTable path={ADMIN_CODES_PATH} />
                        <UsersTable path={ADMIN_USERS_PATH} />
                        <BookTagsTable path={ADMIN_BOOK_TAGS_PATH} />
                        <SchoolsTable path={ADMIN_SCHOOLS_PATH} />
                        <PasswordResetRequestsTable path={ADMIN_PASSWORD_RESET_REQUESTS_PATH} />
                        <SettingsTable path={ADMIN_SETTINGS_PATH} />
                    </AdminPageRouter>
                </Col>
            </Row>
        </Container>
    )
}

function AdminPageRouter(props) {
    const { path, defaultRoute, children } = props;
    return children.find(child => child.props.path === path) || defaultRoute;
}