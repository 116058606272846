import React, {useCallback, useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import PageTitle from "../common/PageTitle";
import {initialPageData, LoadingTable} from "../common/LoadingTable";
import SchoolDetails from "./SchoolDetails";
import useToken from "../../../utils/hooks/useToken";
import {getSchools} from "../../api/AdminApi";

export default function SchoolsTable() {
    const { token } = useToken();

    const [schoolDetails, setSchoolDetails] = useState(undefined);
    const [showNewSchoolDialog, setShowNewSchoolDialog] = useState(false);

    const [schools, setSchools] = useState(undefined);
    const [pageData, setPageData] = useState(initialPageData());
    const [isLoading, setLoading] = useState(true);

    const schoolsCallback = useCallback(() => {
        setLoading(true);
        getSchools(token, pageData.currentPage, pageData.pageSize)
            .then(data => setSchools(data))
            .finally(() => setLoading(false));
    }, [token, pageData]);

    useEffect(() => {
        schoolsCallback();
    }, [schoolsCallback]);

    function rowProvider(school) {
        return (<SchoolRow key={school.id} rowNo={school.id} onRowClick={rowClicked} {...school} />);
    }

    function handleNavChange(page, pageSize) {
        setPageData(pageData => ({ ...pageData, currentPage: page, pageSize: pageSize }));
    }

    function rowClicked(row) {
        setSchoolDetails(schools.find(tag => tag.id === row));
    }

    function addSchoolClicked() {
        setShowNewSchoolDialog(true);
    }

    function schoolDialogClosed() {
        schoolsCallback();
        setShowNewSchoolDialog(false);
        setSchoolDetails(undefined);
    }

    return (
        <>
            <PageTitle title="Šole">
                <Button onClick={addSchoolClicked}>Dodaj šolo</Button>
            </PageTitle>

            <LoadingTable
                isLoading={isLoading}
                initialPageSize={pageData.pageSize}
                columns={['#', 'Ime šole']}
                items={schools}
                rowProvider={rowProvider}
                onNavChange={handleNavChange} />

            {schoolDetails !== undefined && <SchoolDetails show={true} schoolData={schoolDetails} onHide={schoolDialogClosed} />}

            {showNewSchoolDialog && (<SchoolDetails show={showNewSchoolDialog} onHide={schoolDialogClosed} />)}
        </>
    )
}

function SchoolRow({ rowNo, name, onRowClick }) {
    return (
        <tr onClick={() => onRowClick(rowNo)} style={{ cursor: 'pointer' }}>
            <td>{rowNo}</td>
            <td>{name}</td>
        </tr>
    )
}