import React, {useCallback, useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import PageTitle from "../common/PageTitle";
import {initialPageData, LoadingTable} from "../common/LoadingTable";
import BookTagDetails from "./BookTagDetails";
import useToken from "../../../utils/hooks/useToken";
import {getTags} from "../../api/AdminApi";

export default function BookTagsTable() {
    const { token } = useToken();

    const [tagDetails, setTagDetails] = useState(undefined);
    const [showNewTagDialog, setShowNewTagDialog] = useState(false);

    const [tags, setTags] = useState(undefined);
    const [pageData, setPageData] = useState(initialPageData());
    const [isLoading, setLoading] = useState(true);

    const tagsCallback = useCallback(() => {
        setLoading(true);
        getTags(token, pageData.currentPage, pageData.pageSize)
            .then(data => setTags(data))
            .finally(() => setLoading(false));
    }, [token, pageData]);

    useEffect(() => {
        tagsCallback();
    }, [tagsCallback]);

    function rowProvider(tag) {
        return (<BookTagRow key={tag.id} rowNo={tag.id} onRowClick={rowClicked} {...tag} />);
    }

    function handleNavChange(page, pageSize) {
        setPageData(pageData => ({ ...pageData, currentPage: page, pageSize: pageSize }));
    }

    function rowClicked(row) {
        setTagDetails(tags.find(tag => tag.id === row));
    }

    function addTagClicked() {
        setShowNewTagDialog(true);
    }

    function bookTagDialogClosed() {
        tagsCallback();
        setShowNewTagDialog(false);
        setTagDetails(undefined);
    }

    return (
        <>
            <PageTitle title="Skupine knjig">
                <Button onClick={addTagClicked}>Dodaj skupino</Button>
            </PageTitle>

            <LoadingTable
                isLoading={isLoading}
                initialPageSize={pageData.pageSize}
                columns={['#', 'Skupina knjig', 'Vrstni red']}
                items={tags}
                rowProvider={rowProvider}
                onNavChange={handleNavChange} />

            {tagDetails !== undefined && <BookTagDetails show={true} tagData={tagDetails} onHide={bookTagDialogClosed} />}

            {showNewTagDialog && (<BookTagDetails show={showNewTagDialog} onHide={bookTagDialogClosed} />)}
        </>
    )
}

function BookTagRow({ rowNo, name, order, onRowClick }) {
    return (
        <tr onClick={() => onRowClick(rowNo)} style={{ cursor: 'pointer' }}>
            <td>{rowNo}</td>
            <td>{name}</td>
            <td>{order > -1 ? order : 'Sekcija samo za admin'}</td>
        </tr>
    )
}