import {useState} from 'react';
import {loadUser, storeUser} from "../userUtils";

export default function useToken() {
    const getToken = () => {
        const user = loadUser();
        return user?.token;
    }

    const [token, setToken] = useState(getToken());

    const saveUser = (user) => {
        storeUser(user)
        setToken(user?.token);
    }

    return {
        setToken: saveUser,
        token
    }
}