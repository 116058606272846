import React, {useCallback, useEffect, useMemo, useState} from 'react';
import './Book.css';
import {useLocation, useHistory} from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TopNavigation from "../common/TopNavigation";
import CurrentTopicHeader from "./CurrentTopicHeader";
import {VariableSizeList} from "react-window";
import {Document, Page} from "react-pdf";
import BookActivationDialog from "../Home/BookActivationDialog";
import SettingsDialog from "../Home/SettingsDialog";
import SideMenu from "./SideMenu";
import useToken from "../../utils/hooks/useToken";
import {BOOK_PATH, redirectToHome, redirectToLogin} from "../../utils/navUtils";
import {getBookPages, getBookTopics} from "../../api/BooksApi";
import {SessionExpiredError} from "../../utils/errors";
import * as Config from "../../config";
import useWindowDimensions from "../../utils/hooks/useWindowDimensions";
import {getTopicsWithAncestors} from "../../utils/topicUtils";

const DEFAULT_PAGE_WIDTH = 566.929;
const DEFAULT_PAGE_HEIGHT = 793.701;
const DEFAULT_PAGE_WH_RATIO = DEFAULT_PAGE_WIDTH / DEFAULT_PAGE_HEIGHT;
const ITEM_WIDTH = 1160;
const ITEM_HEIGHT = Math.floor(ITEM_WIDTH / DEFAULT_PAGE_WH_RATIO);
const INITIAL_SCALE = ITEM_WIDTH / DEFAULT_PAGE_WIDTH;

const NAVBAR_HIDE_MIN_SCROLL = 100;
const PAGE_CHANGE_OFFSET = 20;

const listRef = React.createRef();
const documentRef = React.createRef();

function getOffsetLeft(element) {
    if (!element) return 0;
    return element.offsetLeft + getOffsetLeft(element.offsetParent);
}

export default function Book(props) {
    const { token, setToken } = useToken();
    const { width } = useWindowDimensions();
    const [contentOffsetLeft, setContentOffsetLeft] = useState(0);

    const location = useLocation();
    const history = useHistory();

    const [bookId] = useState(location.pathname.match(BOOK_PATH)[1]);
    const [pages, setPages] = useState(null);
    const [topics, setTopics] = useState([]);
    const [orderedTopics, setOrderedTopics] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [currentTopic, setCurrentTopic] = useState(null);
    const [rootTopic, setRootTopic] = useState(null);

    const [needsLogout, setNeedsLogout] = useState(false);

    const [hideNavbar, setHideNavbar] = useState(false);
    const [containerStyle, setContainerStyle] = useState('document-container-navbar-and-header-offset');

    const [showBookActivationDialog, setShowBookActivationDialog] = useState(false);
    const [showSettingsDialog, setShowSettingsDialog] = useState(false);

    const pagesCallback = useCallback(() => {
        getBookPages(token, bookId)
            .then(pages => setPages(pages))
            .catch(error => setNeedsLogout(error instanceof SessionExpiredError));
    }, [token, bookId]);

    const topicsCallback = useCallback(() => {
        getBookTopics(token, bookId)
            .then(topics => setTopics(topics));
    }, [token, bookId]);

    useEffect(() => {
        pagesCallback();
        topicsCallback();
    }, [pagesCallback, topicsCallback]);

    useEffect(() => {
        setOrderedTopics(getTopicsWithAncestors(topics));
    }, [topics]);

    useEffect(() => {
        if (hideNavbar) setContainerStyle('document-container-header-offset');
        else setContainerStyle('document-container-navbar-and-header-offset');
    }, [hideNavbar, topics]);

    const handleLogout = useCallback((errorMessage = '') => {
        setToken(null);
        redirectToLogin(history, { errorMessage: errorMessage });
    }, [setToken, history]);

    useEffect(() => {
        if (needsLogout) handleLogout('Seja je pretekla');
    }, [needsLogout, handleLogout]);

    useEffect(() => {
        setContentOffsetLeft(getOffsetLeft(documentRef.current));
    }, [width]);

    const handleDialogClose = () => {
        setShowBookActivationDialog(false);
        setShowSettingsDialog(false);
    }

    const openActivateDialog = () => {
        setShowSettingsDialog(false);
        setShowBookActivationDialog(true);
    }

    const openSettingsDialog = () => {
        setShowBookActivationDialog(false);
        setShowSettingsDialog(true);
    }

    const pageSelected = page => {
        if (page < 1 || page > pages.length) return;

        let scrollDistance = 0;

        pages.forEach(p => {
            if (p.pageNumber >= page) return;

            const pageWidth = p.width || DEFAULT_PAGE_WIDTH;
            const pageHeight = p.height || DEFAULT_PAGE_HEIGHT;
            const itemWidth = width > ITEM_WIDTH ? ITEM_WIDTH : width;

            scrollDistance += itemWidth / pageWidth * pageHeight;
        });

        listRef.current.scrollTo(Math.round(scrollDistance));
    }

    const logoSelected = () => {
        redirectToHome(history);
    }

    const pageChanged = page => {
        setCurrentPage(page);

        const currentPageTopics = orderedTopics.filter(t => t.page === page);
        const previousTopics = orderedTopics.filter(t => t.page <= page);
        const currentTopic = currentPageTopics.length ? currentPageTopics[0] : (previousTopics.length ? previousTopics[previousTopics.length - 1] : null);
        setCurrentTopic(currentTopic?.ancestors?.length ? currentTopic : null);

        const rootTopicId = currentTopic?.ancestors ? currentTopic.ancestors[currentTopic.ancestors.length - 1] : null;
        const rootTopic = orderedTopics.find(t => t.id === rootTopicId);
        setRootTopic(currentTopic?.ancestors?.length ? rootTopic : currentTopic);
    }

    const scrollChanged = (direction, offset) => {
        setHideNavbar( direction === 'forward' && offset > NAVBAR_HIDE_MIN_SCROLL);
    }

    return (
        <>
            <Container fluid className="root-book-container">
                <Row noGutters>
                    <Col className="vh-100 overflow-auto hide-scrollbar">
                        <Row noGutters>
                            <Col>
                                <TopNavigation
                                    hide={hideNavbar}
                                    onBookActivate={() => openActivateDialog()}
                                    onSettings={() => openSettingsDialog()}
                                    onLogout={() => handleLogout()} />
                                <CurrentTopicHeader
                                    bookId={bookId}
                                    topics={orderedTopics}
                                    contentOffset={contentOffsetLeft}
                                    contentWidth={width > ITEM_WIDTH ? ITEM_WIDTH : width}
                                    title={rootTopic?.name}
                                    subtitle={currentTopic?.name}
                                    currentPage={currentPage}
                                    maxPage={pages?.length}
                                    navbarHidden={hideNavbar}
                                    onPageSelected={pageSelected} />
                            </Col>
                        </Row>
                        <Row noGutters>
                            <Col>
                                <Row noGutters className="horizontal-center">
                                    <div ref={documentRef} className={`document-container ${containerStyle}`}>
                                        <DocumentPages pages={pages} onPageChanged={pageChanged} onScrollChanged={scrollChanged} />
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <SideMenu bookId={bookId} topics={orderedTopics} currentPage={currentPage} maxPage={pages?.length} onPageSelected={pageSelected} onLogoSelected={logoSelected} />

            {showBookActivationDialog && <BookActivationDialog show={showBookActivationDialog} onHide={handleDialogClose} />}
            {showSettingsDialog && <SettingsDialog show={showSettingsDialog} onHide={handleDialogClose} />}
        </>
    );
}

function ListRow({ item, index, style, onDocumentSuccess, onDocumentLoad }) {
    // const itemStyle = { ...style, border: '2px solid red' };
    const itemStyle = { ...style };
    return (
        <div style={itemStyle}>
            <DocumentPage data={item} pageNumber={index + 1} onDocumentSuccess={onDocumentSuccess} onDocumentLoad={onDocumentLoad} />
        </div>
    );
}

// const renderItem = useCallback(({ item, index, size, marginLeft, marginTop }) => {
function ItemRenderer({ data, index, style }) {
    const item = data.pages[index];

    function onDocumentSuccess(data) {
        // console.log(`Rendered ${index + 1}`);
        // console.log(data);
    }

    return <ListRow key={index} item={item} index={index} style={style} onDocumentSuccess={onDocumentSuccess} onDocumentLoad={data.onDocumentLoad} />;
}

function DocumentPages({ pages, onPageChanged, onScrollChanged, ...rest }) {
    const { height, width } = useWindowDimensions();
    const [currentPage, setCurrentPage] = useState(1);

    const itemWidth = useCallback(() => width > ITEM_WIDTH ? ITEM_WIDTH : width, [width]);
    const itemHeight = useCallback(() => Math.floor(itemWidth() / ITEM_WIDTH * ITEM_HEIGHT), [itemWidth]);

    const keypressJump = itemHeight() / 4.0;
    const keypressMove = itemHeight() / 16.0;
    const endPos = (pages?.length || 0) * itemHeight();

    const handleKeyup = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();

        switch (e.keyCode) {
            case 36: // home
                listRef.current?.scrollTo(0);
                break;
            case 35: // end
                listRef.current?.scrollTo(endPos);
                break;
            case 33: // page up
                listRef.current?.scrollTo(listRef.current.state.scrollOffset - keypressJump);
                break;
            case 34: // page down
                listRef.current?.scrollTo(listRef.current.state.scrollOffset + keypressJump);
                break;
            case 38: // arrow up
                listRef.current?.scrollTo(listRef.current.state.scrollOffset - keypressMove);
                break;
            case 40: // arrow down
                listRef.current?.scrollTo(listRef.current.state.scrollOffset + keypressMove);
                break;
            default:
                break;
        }
    }, [endPos, keypressJump, keypressMove]);

    useEffect(() => {
        document.addEventListener("keyup", handleKeyup);
        return () => document.removeEventListener("keypress", handleKeyup);
    }, [handleKeyup]);

    const onScroll = ({ scrollDirection, scrollOffset, scrollUpdateWasRequested }) => {
        // console.log(`d: ${scrollDirection}  o: ${scrollOffset}  r: ${scrollUpdateWasRequested}`);

        const pageCount = pages?.length || 0;

        let currentHeight = 0;
        let page = 1;

        for (let i = 0; i < pageCount; i++) {
            const p = pages[i];

            const pageWidth = p.width || DEFAULT_PAGE_WIDTH;
            const pageHeight = p.height || DEFAULT_PAGE_HEIGHT;
            const itemWidth = width > ITEM_WIDTH ? ITEM_WIDTH : width;
            const itemHeight = itemWidth / pageWidth * pageHeight;

            currentHeight += Math.round(itemHeight);

            // We consider it is next page, when we are just before that page.
            if (currentHeight >= scrollOffset + PAGE_CHANGE_OFFSET) {
                page = i + 1;
                break;
            }
        }

        if (page !== currentPage) {
            console.log(`New page: ${page} ... ${currentHeight}`);
            setCurrentPage(page);
            onPageChanged(page);
        }

        onScrollChanged(scrollDirection, scrollOffset);
    }

    const onDocumentLoad = useCallback((page, pageNumber) => {
        listRef.current?.resetAfterIndex(pageNumber - 1);
    }, []);

    const itemData = useMemo(() => ({
        pages,
        onDocumentLoad
    }), [pages, onDocumentLoad]);

    const getItemSize = useCallback(index => {
        const height = Math.round(itemWidth() / pages[index].width * pages[index].height);
        return height || itemHeight();
    }, [pages, itemWidth, itemHeight]);

    return (
        <VariableSizeList
            {...rest}
            ref={listRef}
            className="no-scrollbars"
            itemData={itemData}
            height={height}
            itemCount={pages?.length || 0}
            itemSize={getItemSize}
            width={`${itemWidth()}px`}
            style={{ overflowX: 'hidden' }}
            onScroll={onScroll}
        >
            {ItemRenderer}
        </VariableSizeList>
    );
}

function DocumentPage({ data, pageNumber, onDocumentSuccess, onDocumentLoad }) {
    const { token, setToken } = useToken();
    const history = useHistory();
    const { width } = useWindowDimensions();

    const [pageUrl, setPageUrl] = useState(null);
    const [scale, setScale] = useState(INITIAL_SCALE);
    const [pageWidth, setPageWidth] = useState(ITEM_WIDTH);

    function onDocumentError(error) {
        // console.log(error);

        if (error?.status === 401) {
            setToken(null);
            redirectToLogin(history, { errorMessage: 'Seja je potekla' });
        }
    }

    const onPageLoad = useCallback(page => {
        onDocumentLoad(page, pageNumber);
    }, [pageNumber, onDocumentLoad]);

    useEffect(() => {
        if (!data) return;

        let url = data.pdfUrl;

        if (!url.startsWith('/')) url = `/${url}`;

        setPageUrl(`${Config.API_URL}${url}`);
    }, [data]);

    const updatePageWidth = useCallback(() => {
        const newWidth = Math.min(ITEM_WIDTH, width);
        if (pageWidth !== newWidth) {
            setScale(newWidth / DEFAULT_PAGE_WIDTH);
            setPageWidth(newWidth);
        }
    }, [width, pageWidth]);

    useEffect(() => {
        updatePageWidth();
    }, [updatePageWidth]);

    return (
        <>
            {pageUrl &&
                <MemoizedSinglePageDocument
                    pageUrl={pageUrl}
                    token={token}
                    scale={scale}
                    width={pageWidth}
                    onDocumentSuccess={onDocumentSuccess}
                    onDocumentError={onDocumentError}
                    onPageLoad={onPageLoad} />}
        </>
    );
}

function SinglePageDocument({ pageUrl, token, scale, width, onDocumentSuccess, onDocumentError, onPageLoad }) {
    return (
        <Document
            file={{ url: pageUrl, withCredentials: true, httpHeaders: { Authorization : `Bearer ${token}` }}}
            options={{ spreadModeOnLoad: 0}}
            loading={() => <div className="text-center no-padding" style={{ marginTop: '5em' }}>Nalagam...</div>}
            error={() => <div className="no-padding text-center" style={{ marginTop: '5em' }}>Napaka pri nalaganju strani</div>}
            style={{ width: `${width}px` }}
            onLoadSuccess={onDocumentSuccess}
            onLoadError={onDocumentError}
        >
            <Page
                scale={scale}
                pageNumber={1}
                onLoadSuccess={onPageLoad}
            />
        </Document>
    );
}

function pagePropsAreEqual(prevPage, nextPage) {
    return prevPage.pageUrl === nextPage.pageUrl && prevPage.token === nextPage.token && prevPage.width === nextPage.width;
}

const MemoizedSinglePageDocument = React.memo(SinglePageDocument, pagePropsAreEqual);