import React from "react";
import PropTypes from "prop-types";
import './LoginTitle.css';

function LoginTitle(props) {
    return (
        <div id="login-title" className="text-extra-large main-text-color">
            <div className="title">{props.title}</div>
            {props.children
                ? (<LoginSubtitle>{props.children}</LoginSubtitle> )
                : ''}
        </div>
    );
}

function LoginSubtitle(props) {
    return (
        <div className="title">{props.children}</div>
    )
}

LoginTitle.propTypes = {
    title: PropTypes.string,
}

export default LoginTitle;