import React from "react";
import './LoginPage.css';
import {useHistory} from "react-router-dom";
import Background from "../../components/Login/common/Background";
import Logo from "../../components/Logo/Logo";
import Login from "../../components/Login/Login/Login";
import LoginSpacing from "../../components/Login/common/LoginSpacing";
import LoginDivider from "../../components/Login/common/LoginDivider";
import InfoPanel from "../../components/Login/common/InfoPanel";
import useToken from "../../utils/hooks/useToken";
import {redirectToHome} from "../../utils/navUtils";

export default function LoginPage(props) {
    const { token, setToken } = useToken();
    const history = useHistory();

    if (token) redirectToHome(history);

    return (
        <>
            <Background />
            <div className="login-container shadow border-0">
                <Logo />
                <Login setUser={setToken} showError={props.showError} />
                <LoginSpacing />
                <LoginDivider />
                <LoginSpacing />
                <InfoPanel />
            </div>
        </>
    );
}