import React from "react";
import './AdminNavBar.css';
import {useLocation} from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import {ADMIN_BOOK_TAGS_PATH, ADMIN_CODES_PATH, ADMIN_PASSWORD_RESET_REQUESTS_PATH, ADMIN_PATH, ADMIN_SCHOOLS_PATH, ADMIN_SETTINGS_PATH, ADMIN_USERS_PATH} from "../../../utils/navUtils";

export default function AdminNavBar({ onLogout }) {
    const location = useLocation()

    return (
        <Navbar bg="dark" variant="dark" className="fixed-top admin-navbar">
            <Navbar.Brand href={ADMIN_PATH} className="font-weight-bold text-center d-none d-sm-none d-md-block admin-navbar-brand">
                JUTRO.si
            </Navbar.Brand>
            <Nav className="mr-auto" variant="pills" defaultActiveKey={location.pathname}>
                <Nav.Item className="text-center">
                    <Nav.Link href={ADMIN_PATH} className="admin-navbar-item">Knjige</Nav.Link>
                </Nav.Item>
                <Nav.Item className="text-center">
                    <Nav.Link href={ADMIN_USERS_PATH} className="admin-navbar-item">Uporabniki</Nav.Link>
                </Nav.Item>
                <Nav.Item className="text-center">
                    <Nav.Link href={ADMIN_CODES_PATH} className="admin-navbar-item">Aktivacijske kode</Nav.Link>
                </Nav.Item>
                <Nav.Item className="text-center">
                    <Nav.Link href={ADMIN_BOOK_TAGS_PATH} className="admin-navbar-item">Sekcije knjig</Nav.Link>
                </Nav.Item>
                <Nav.Item className="text-center">
                    <Nav.Link href={ADMIN_SCHOOLS_PATH} className="admin-navbar-item">Šole</Nav.Link>
                </Nav.Item>
                <Nav.Item className="text-center">
                    <Nav.Link href={ADMIN_PASSWORD_RESET_REQUESTS_PATH} className="admin-navbar-item">Ponastavitve gesel</Nav.Link>
                </Nav.Item>
                <Nav.Item className="text-center">
                    <Nav.Link href={ADMIN_SETTINGS_PATH} className="admin-navbar-item">Nastavitve</Nav.Link>
                </Nav.Item>
            </Nav>
            <Button className="btn btn-danger font-weight-bold" onClick={onLogout}>Odjava</Button>
        </Navbar>
    )
}