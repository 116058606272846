import React, {useEffect, useState} from "react";
import './SideMenu.css';
import '../../resources/styles/Common.css';
import SearchIcon from "./SearchIcon";
import TableOfContents from "./TableOfContents";
import Search from "./Search";
import button from "bootstrap/js/src/button";
import Logo from "../Logo/Logo";
import Editable from "../common/Editable";

export default function SideMenu({ bookId, topics, currentPage, maxPage, onPageSelected, onLogoSelected }) {
    const [showTableOfContents, setShowTableOfContents] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [showPageSelector, setShowPageSelector] = useState(false);
    const [page, setPage] = useState(currentPage);

    useEffect(() => {
        setPage(currentPage);
    }, [currentPage]);

    function hideDialogs() {
        setShowTableOfContents(false);
        setShowSearch(false);
        setShowPageSelector(false);
    }

    function showTocDialog() {
        hideDialogs();
        setShowTableOfContents(true);
    }

    function showSearchDialog() {
        hideDialogs();
        setShowSearch(true);
    }

    function showPageSelectorField() {
        hideDialogs();
        setShowPageSelector(true);
    }

    const pageSelected = () => {
        onPageSelected(page);
    }

    const handleChange = e => {
        const newPage = e.target.value;

        if (newPage && newPage < 1) return;
        if (newPage && maxPage && newPage > maxPage) return;

        setPage(e.target.value);
    }

    const handleKeyDown = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            e.target.blur();

            pageSelected();
        }
    }

    return (
        <>
            <div className="book-side-menu">
                <SideMenuButton className="button-wide" onClick={onLogoSelected}><Logo /></SideMenuButton>
                {topics.length !== 0 && <SideMenuButton className="button-wide" onClick={showTocDialog}>KAZALO</SideMenuButton>}
                <SideMenuButton className="button-wide" onClick={showPageSelectorField}>STRAN: <Editable text={currentPage} type="input" editingMode={showPageSelector} onBlur={() => setShowPageSelector(false)}> <input autoFocus className="main-border-color text-large" type="number" id="side-menu-page" value={page.toString()} autoComplete="off" style={{ width: '3.5rem' }} onChange={handleChange} onKeyDown={handleKeyDown} /></Editable>
                </SideMenuButton>
                <SideMenuButton onClick={showSearchDialog}><SearchIcon /></SideMenuButton>
            </div>

            {showTableOfContents && <TableOfContents topics={topics} show={showTableOfContents} onHide={hideDialogs} onPageSelected={onPageSelected} />}
            {showSearch && <Search bookId={bookId} show={showSearch} onHide={hideDialogs} onPageSelected={onPageSelected} />}
        </>
    );
}

function SideMenuButton({ className = '', ...props }) {
    return (
        <>
            <div className={`main-background-color book-side-menu-button ${className}`}>
                <button type="button" className="font-weight-bold no-padding no-margin main-background-color light-text-color" onClick={props.onClick}>{props.children}</button>
            </div>
            <br />
        </>
    );
}