import React from "react";
import PropTypes from "prop-types";
import {Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";

function FaqSection(props) {
    return (
        <div>
            <h5>{props.title}</h5>
            {props.children || (<br />)}
        </div>
    );
}

function Faq(props) {
    return (
        <Modal {...props} animation={false} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Pogosto zastavljena vprašanja
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FaqSection title="Kako lahko dostopam do e-učbenika?">
                    <p>Uporabnik mora e-učbenik najprej kupiti in aktivirati pri čemer ustvari uporabniški račun. Z njim lahko v času veljavnosti kadarkoli dostopa do kupljenega gradiva. Veljavnost je omejena na 365 dni od dneva aktivacije, istočasno lahko gradivo uporablja le na eni napravi. Gradivo lahko kupite na <a href="https://www.jutro.si">www.jutro.si</a>, za več informacij sledite rubrikam <b>Nakup e-učbenika</b> in <b>Kako aktiviram e-učbenik?</b>.</p>

                    <p>Učitelji, ki pri poučevanju uporabljajo naša gradiva imajo brezplačen dostop do e-gradiv. Za pridobitev dostopa nam pošljite zahtevek na info@jutro.si. Slednji mora vsebovati naziv šole, ime in priimek ter predmet, ki ga učitelj poučuje ter izrecno izjavo, da uporablja naša gradiva.</p>
                </FaqSection>
                <FaqSection title="Nakup e-učbenika">
                    <p>E-učbenik lahko kupite na <a href="https://www.jutro.si">www.jutro.si</a>. Pri plačilu s kartico vam sistem takoj avtomatsko pošlje dostopno kodo, ob plačilu po povzetju ali po predračunu, pa vam jo pošljemo po prejetem plačilu oz. skupaj z ostalimi gradivi.</p>

                    <p>Trenutno dostopnih kod ni mogoče kupiti v knjigarnah in papirnicah.</p>
                </FaqSection>
                <FaqSection title="Nakup fizičnega učbenika">
                    <p>Učbenik lahko kupite preko portala www-jutro.si ali v katerikoli izmed naslednjih knjigarn <a href="https://jutro.si/knjigarne/">www.jutro.si/knjigarne</a>. Vsa gradiva imamo na zalogi čez celo leto.</p>
                </FaqSection>
                <FaqSection title="Kako aktiviram e-učbenik?">
                    <p>Navodila za prvo aktivacijo si lahko ogledate v spodnjem videoposnetku.</p>
                </FaqSection>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Zapri</Button>
            </Modal.Footer>
        </Modal>
    );
}

FaqSection.propTypes = {
    title: PropTypes.string
}

export default Faq;