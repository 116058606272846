import React, {useCallback, useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import './HomePage.css';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TopNavigation from "../components/common/TopNavigation";
import Books from "../components/Home/Books";
import BookActivationDialog from "../components/Home/BookActivationDialog";
import SettingsDialog from "../components/Home/SettingsDialog";
import Snackbar from "../components/common/Snackbar";
import {redirectToLogin} from "../utils/navUtils";
import useToken from "../utils/hooks/useToken";
import {getGroupedBooks} from "../api/BooksApi";
import {SessionExpiredError} from "../utils/errors";

function HomePage(props) {
    const { token, setToken } = useToken();

    const [groupedBooks, setGroupedBooks] = useState(undefined)
    const [message, updateMessage] = useState(null);
    const [needsLogout, setNeedsLogout] = useState(false);

    const [showBookActivationDialog, setShowBookActivationDialog] = useState(false);
    const [showSettingsDialog, setShowSettingsDialog] = useState(false);

    const handleLogout = useCallback((errorMessage = '') => {
        setToken(null);
        redirectToLogin(props.history, { errorMessage: errorMessage });
    }, [setToken, props.history]);

    useEffect(() => {
        if (needsLogout) handleLogout('Seja je pretekla');
    }, [needsLogout, handleLogout]);

    const groupedBooksCallback = useCallback(() => {
        getGroupedBooks(token)
            .then(books => setGroupedBooks(books))
            .catch(error => {
                if (error instanceof SessionExpiredError) setNeedsLogout(true);
                else updateMessage(error.message);
            });
    }, [token]);

    useEffect(() => {
        groupedBooksCallback();
    }, [groupedBooksCallback]);

    const handleDialogClose = (needsReload = false) => {
        if (needsReload) {
            groupedBooksCallback();
        }

        setShowBookActivationDialog(false);
    }

    const handleActivateClick = bookId => {
        openActivateDialog();
    }

    const openActivateDialog = () => {
        setShowSettingsDialog(false);
        setShowBookActivationDialog(true);
    }

    const openSettingsDialog = () => {
        setShowBookActivationDialog(false);
        setShowSettingsDialog(true);
    }

    return (
        <>
            <Container fluid className="px-0">
                <Row noGutters>
                    <Col className="vh-100 overflow-auto hide-scrollbar">
                        <Row noGutters>
                            <Col>
                                <TopNavigation
                                    onBookActivate={() => openActivateDialog()}
                                    onSettings={() => openSettingsDialog()}
                                    onLogout={() => handleLogout()} />
                            </Col>
                        </Row>
                        <Row noGutters>
                            <Col>
                                <Books books={groupedBooks} onActivateBook={handleActivateClick} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            {showBookActivationDialog && <BookActivationDialog show={showBookActivationDialog} onHide={handleDialogClose} />}
            {showSettingsDialog && <SettingsDialog show={showSettingsDialog} onHide={() => setShowSettingsDialog(false)} />}

            <Snackbar errorMessage={message} updateErrorMessage={updateMessage} />
        </>
    );
}

export default withRouter(HomePage);