import React from "react";
import './LoginPage.css';
import Background from "../../components/Login/common/Background";
import Logo from "../../components/Logo/Logo";
import Activation from "../../components/Login/Activation/Activation";
import LoginSpacing from "../../components/Login/common/LoginSpacing";
import LoginDivider from "../../components/Login/common/LoginDivider";
import InfoPanel from "../../components/Login/common/InfoPanel";

function ActivationPage(props) {
    return (
        <>
            <Background />
            <div className="login-container shadow border-0">
                <Logo />
                <Activation showError={props.showError} />
                <LoginSpacing />
                <LoginDivider />
                <LoginSpacing />
                <InfoPanel />
            </div>
        </>
    );
}

export default ActivationPage;