import './Background.css';

function Background(props) {
    return (
        <div id="bg">
            {props.children}
        </div>
    );
}

export default Background;