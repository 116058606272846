import React, {useCallback, useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {LabeledInput, LabeledIntegerInput} from "../common/LabeledInput";
import {initialPageData, LoadingTable} from "../common/LoadingTable";
import Snackbar from "../../../components/common/Snackbar";
import useToken from "../../../utils/hooks/useToken";
import {createNewTag, getTagBooks, updateTag} from "../../api/AdminApi";

const EmptyTag = {
    name: '',
    order: -1
}

export default function BookTagDetails({ tagData, ...props }) {
    const { token } = useToken();

    const [message, updateMessage] = useState(null);

    const [tag, setTag] = useState(prepareData(tagData));

    const [books, setBooks] = useState(undefined);
    const [pageData, setPageData] = useState(initialPageData());
    const [isLoading, setLoading] = useState(true);
    const [isNew] = useState(tagData === undefined);

    const tagBooksCallback = useCallback(() => {
        setLoading(true);
        getTagBooks(token, tag?.id, pageData.currentPage, pageData.pageSize)
            .then(data => setBooks(data))
            .finally(() => setLoading(false));
    }, [token, tag?.id, pageData]);

    useEffect(() => {
        if (tag?.id === undefined) return;
        tagBooksCallback();
    }, [tagBooksCallback, tag?.id]);

    function update(key, value) {
        let updated = tag;
        updated[key] = value;
        setTag(tag => ({ ...tag, ...updated }));
    }

    function prepareData(tagData) {
        return tagData || EmptyTag;
    }

    async function addClicked() {
        if (!validate()) return;

        if (isNew) {
            await createNewTag(token, tag);
        } else {
            await updateTag(token, tag);
        }

        props.onHide();
    }

    function validate() {
        let errorMessage = '';

        if (!tag.name) errorMessage = 'Vnesi naziv skupine';
        else if (tag.name.length < 3) errorMessage = 'Naziv mora imeti vsaj 3 znake';
        else if (!tag.order) errorMessage = 'Vnesi vrstni red';
        else if (!Number.isInteger(parseInt(tag.order))) errorMessage = 'Vrstni red mora biti cela številka';
        else if (!Number.isSafeInteger(parseInt(tag.order))) errorMessage = 'Vrstni red vsebuje preveliko številko';

        if (errorMessage.length > 0) {
            updateMessage(errorMessage);
            return false;
        }

        return true;
    }

    function handleNavChange(page, pageSize) {
        setPageData(pageData => ({ ...pageData, currentPage: page, pageSize: pageSize }));
    }

    return (
        <Modal {...props} animation={false} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {!isNew ? tag?.name : 'Nova skupina knjig'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LabeledInput label='Naziv' fieldId='tagName' value={tag.name} onChange={e => update('name', e.target.value)} /><br />
                <LabeledIntegerInput label='Vrstni red' fieldId='tagOrder' value={tag.order} onChange={e => update('order', e.target.value)} /><br />

                {!isNew && (
                    <>
                        <p>Knjige:</p>
                        <LoadingTable
                            isLoading={isLoading}
                            initialPageSize={pageData.pageSize}
                            columns={['#', 'Naslov', 'Opis']}
                            fields={['id', 'name', 'description']}
                            items={books}
                            onNavChange={handleNavChange} />
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={props.onHide}>Prekliči</Button>
                <Button onClick={addClicked}>{isNew ? 'Dodaj' : 'Shrani'}</Button>
            </Modal.Footer>

            <Snackbar errorMessage={message} updateErrorMessage={updateMessage} />
        </Modal>
    );
}