import React from "react";
import PropTypes from "prop-types";
import './ActivationInfo.css';
import '../../../resources/styles/Common.css';

function ActivationInfo(props) {
    const title = props.tagName ? 'Aktivirani bodo učbeniki iz skupine' : 'Aktiviran bo učbenik';
    const value = props.tagName || props.bookName;

    return (
        <div className={props.className || 'activation-info'} hidden={props.hidden}>
            <span className="main-text-color text-regular">{title}:</span>&nbsp;
            <span className="book dark-text-color text-regular">{value}</span>
        </div>
    );
}

ActivationInfo.propTypes = {
    hidden: PropTypes.bool,
    className: PropTypes.string,
    bookName: PropTypes.string,
    tagName: PropTypes.string,
}

export default ActivationInfo;