import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {LabeledMultilineInput} from "../common/LabeledInput";
import useToken from "../../../utils/hooks/useToken";
import {updateSetting} from "../../api/AdminApi";

export default function SettingDetails({ settingData, ...props }) {
    const { token } = useToken();

    const [setting, setSetting] = useState(settingData);

    function update(key, value) {
        let updated = setting;
        updated[key] = value;
        setSetting(setting => ({ ...setting, ...updated }));
    }

    async function saveClicked() {
        if (setting?.isReadOnly === false) await updateSetting(token, setting);
        props.onHide();
    }

    return (
        <Modal {...props} animation={false} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {setting?.setting}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LabeledMultilineInput label='Vrednost' fieldId='settingValue' value={setting.value} readOnly={setting?.isReadOnly !== false} onChange={e => update('value', e.target.value)} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant={`${setting?.isReadOnly === false ? "outline-primary" : "primary"}`} onClick={props.onHide}>{setting?.isReadOnly === false ? 'Prekliči' : 'Zapri'}</Button>
                {setting?.isReadOnly === false && <Button onClick={saveClicked}>Shrani</Button>}
            </Modal.Footer>
        </Modal>
    );
}