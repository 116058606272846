import * as Config from "../config";
import {ConflictError, NotFoundError, SessionExpiredError, TooManyRequestsError} from "../utils/errors";
import {storeUser} from "../utils/userUtils";

export async function apiGetCall(accessToken, endpoint, page = undefined, limit = undefined, params = []) {
    const urlParams = params || [];
    if (page !== undefined) urlParams.push(`page=${page || 1}`);
    if (limit !== undefined) urlParams.push(`limit=${limit || 0}`);

    const query = urlParams.length ? `?${urlParams.join('&')}` : '';

    return fetch(`${Config.API_URL}/${endpoint}${query}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }
    }).then(data => {
        switch (data.status) {
            case 401: throw new SessionExpiredError();
            case 404: throw new NotFoundError();
            case 429: throw new TooManyRequestsError();
            default: return data.json()
        }
    }).catch(error => {
        if (error instanceof SessionExpiredError) {
            storeUser(null);
            throw error;
        }
        console.log(error);
        return error;
    })
}

export async function apiPostCall(endpoint, payload) {
    return fetch(`${Config.API_URL}/${endpoint}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
    }).then(data => {
        switch (data.status) {
            case 409: throw new ConflictError();
            default: return data.json()
        }
    }).catch(error => {
        console.log(error);
        return error;
    })
}

export async function authorizedApiPostCall(accessToken, endpoint, payload) {
    return authorizedApiCall('POST', accessToken, endpoint, payload);
}

export async function authorizedApiPutCall(accessToken, endpoint, payload) {
    return authorizedApiCall('PUT', accessToken, endpoint, payload);
}

export async function authorizedApiDeleteCall(accessToken, endpoint) {
    return authorizedApiCall('DELETE', accessToken, endpoint, null);
}

export async function authorizedApiCall(method, accessToken, endpoint, payload) {
    return fetch(`${Config.API_URL}/${endpoint}`, {
        method: method,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        },
        body: payload ? JSON.stringify(payload) : null
    }).then(data => {
        switch (data.status) {
            case 401: throw new SessionExpiredError();
            case 404: throw new NotFoundError();
            case 429: throw new TooManyRequestsError();
            default: return data.json()
        }
    }).catch(error => {
        if (error instanceof SessionExpiredError) {
            storeUser(null);
            throw error;
        }
        console.log(error);
        return error;
    })
}