import React, {useCallback, useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {initialPageData, LoadingTable} from "../common/LoadingTable";
import {enableUser, getUserBooks} from "../../api/AdminApi";
import useToken from "../../../utils/hooks/useToken";
import {formattedDate} from "../../../utils/dateUtils";
import EnabledIcon from "./EnabledIcon";
import DisabledIcon from "./DisabledIcon";

export default function UserDetails({ userData, ...props }) {
    const { token } = useToken();

    const [books, setBooks] = useState(undefined);
    const [pageData, setPageData] = useState(initialPageData());
    const [isLoading, setLoading] = useState(true);

    const userBooksCallback = useCallback(() => {
        setLoading(true);
        getUserBooks(token, userData?.id, pageData.currentPage, pageData.pageSize)
            .then(data => setBooks(data))
            .finally(() => setLoading(false));
    }, [token, userData?.id, pageData]);

    useEffect(() => {
        if (userData?.id === undefined) return;
        userBooksCallback();
    }, [userBooksCallback, userData?.id]);

    function rowProvider(user) {
        return (<UserBookRow key={user.id} rowNo={user.id} {...user} />);
    }

    function handleNavChange(page, pageSize) {
        setPageData(pageData => ({ ...pageData, currentPage: page, pageSize: pageSize }));
    }

    function toggleEnable() {
        if (userData === null) return;

        enableUser(token, userData?.id, !userData.enabled)
            .then(data => props.onHide());
    }

    return (
        <Modal {...props} animation={false} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {userData?.firstName} {userData?.lastName}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p><strong>Email</strong> &nbsp; {userData?.email}</p>

                <p>
                    <strong>Omogočen</strong> &nbsp;
                    {userData?.enabled ? <EnabledIcon /> : <DisabledIcon />} &nbsp;
                    <Button variant="outline-primary" onClick={toggleEnable}>{userData?.enabled ? 'Onemogoči' : 'Omogoči'}</Button>
                </p>

                <p><strong>Knjige</strong></p>
                <LoadingTable
                    isLoading={isLoading}
                    initialPageSize={pageData.pageSize}
                    columns={['#', 'Naslov', 'Opis', 'Vrsta aktivacije', 'Veljavno do']}
                    items={books}
                    rowProvider={rowProvider}
                    onNavChange={handleNavChange} />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Zapri</Button>
            </Modal.Footer>
        </Modal>
    );
}

function UserBookRow({ rowNo, name, description, activatedBy, activatedTag, activeTo }) {
    function activationType() {
        if (activatedBy === 'book') return 'Knjiga';

        if (activatedBy === 'tag') {
            const tag = activatedTag?.name ? `: ${activatedTag.name}` : '';
            return `Skupina${tag}`;
        }

        return '';
    }

    return (
        <tr>
            <td>{rowNo}</td>
            <td>{name}</td>
            <td>{description}</td>
            <td>{activationType()}</td>
            <td>{formattedDate(activeTo)}</td>
        </tr>
    )
}