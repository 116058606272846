import React, {useCallback, useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import BookTopic from "./BookTopic";
import {initialSmallPageData, LoadingTable} from "../common/LoadingTable";
import useToken from "../../../utils/hooks/useToken";
import {getBookTopics} from "../../../api/BooksApi";
import {getTopicsWithAncestors} from "../../../utils/topicUtils";

export default function BookToc({ bookData, ...props }) {
    const { token } = useToken();

    const [topics, setTopics] = useState(undefined);
    const [visibleTopics, setVisibleTopics] = useState(undefined);
    const [pageData, setPageData] = useState(initialSmallPageData());
    const [isLoading, setLoading] = useState(true);

    const [topicDetails, setTopicDetails] = useState(undefined);
    const [showNewTopicDialog, setShowNewTopicDialog] = useState(false);

    const topicsCallback = useCallback(() => {
        setLoading(true);
        getBookTopics(token, bookData?.id)
            .then(data => setTopics(getTopicsWithAncestors(data)))
            .finally(() => setLoading(false));
    }, [token, bookData?.id, ]);

    useEffect(() => {
        if (bookData?.id === undefined) return;
        topicsCallback();
    }, [topicsCallback, bookData?.id]);

    useEffect(() => {
        if (topics === undefined) return;
        const start = (pageData.currentPage - 1) * pageData.pageSize;
        const end = start + pageData.pageSize;
        setVisibleTopics(topics.slice(start, end))
    }, [topics, pageData]);

    function rowProvider(topic, index) {
        return (<BookTocRow key={index + 1} rowNo={index + 1} topic={topic} onRowClick={rowClicked} />);
    }

    function handleNavChange(page, pageSize) {
        setPageData(pageData => ({ ...pageData, currentPage: page, pageSize: pageSize }));
    }

    function rowClicked(topic) {
        setTopicDetails(topics.find(t => t.id === topic.id));
    }

    function addTopicClicked() {
        setShowNewTopicDialog(true);
    }

    function topicDialogClosed() {
        topicsCallback();
        setShowNewTopicDialog(false);
        setTopicDetails(undefined);
    }

    return (
        <>
            {topicDetails === undefined && !showNewTopicDialog &&
                <Modal {...props} animation={false} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Kazalo - {bookData?.name}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <LoadingTable
                            isLoading={isLoading}
                            initialPageSize={pageData.pageSize}
                            columns={['Naslov', 'Stran', 'Vrstni red']}
                            items={visibleTopics}
                            rowProvider={rowProvider}
                            onNavChange={handleNavChange}/>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="mr-auto" onClick={addTopicClicked}>Dodaj poglavje</Button>
                        <Button onClick={props.onHide}>Zapri</Button>
                    </Modal.Footer>
                </Modal>
            }

            {topicDetails !== undefined && <BookTopic show={true} topicData={topicDetails} topics={topics} bookData={bookData} onHide={topicDialogClosed}/> }

            {showNewTopicDialog && (<BookTopic show={showNewTopicDialog} topics={topics} bookData={bookData} onHide={topicDialogClosed} />)}
        </>
    );
}

function BookTocRow({ topic, onRowClick }) {
    const indent = `${0.75 * (topic.ancestors.length + 1)}rem`;

    return (
        <tr onClick={() => onRowClick(topic)} style={{ cursor: 'pointer' }}>
            <td style={{ paddingLeft: `${indent}` }}>{topic.name}</td>
            <td>{topic.page}</td>
            <td>{topic.order}</td>
        </tr>
    )
}