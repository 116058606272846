import {apiPostCall, authorizedApiPostCall} from "./common";

export async function checkCode(payload) {
    return apiPostCall('code/check', payload);
}

export async function registerUser(payload) {
    return apiPostCall('users/register', payload);
}

export async function loginUser(credentials) {
    return apiPostCall('users/login', credentials);
}

export async function passwordReset(payload) {
    return apiPostCall('users/passwordRequest', payload);
}

export async function newPassword(payload) {
    return apiPostCall('users/passwordRequest/complete', payload);
}

export async function changePassword(token, payload) {
    return authorizedApiPostCall(token, 'users/passwordReset', payload);
}