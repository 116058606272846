export function getTopicsWithAncestors(topics) {
    if (!Array.isArray(topics)) return [];

    const rootTopicIds = topics.filter(t => t.parentTopicId === null).map(t => t.id);

    const contents = [];
    const nonRootParent = [];

    topics.forEach(topic => {
        if (topic.parentTopicId === null) {
            contents.push({ ...topic, ancestors: [] });
        } else {
            const id = rootTopicIds.find(i => topic.parentTopicId === i);
            if (id) {
                contents.push({ ...topic, ancestors: [ id ] });
            } else {
                nonRootParent.push(topic);
            }
        }
    });

    nonRootParent.forEach(topic => {
        const parentTopic = contents.find(i => topic.parentTopicId === i.id);
        if (parentTopic) contents.push({ ...topic, ancestors: [ parentTopic.id, ...parentTopic.ancestors ] });
    });

    function compare(a, b) {
        const ancestorCountA = a.ancestors.length;
        const ancestorCountB = b.ancestors.length;
        const rootIdA = ancestorCountA > 0 ? a.ancestors[a.ancestors.length - 1] : a.id;
        const rootIdB = ancestorCountB > 0 ? b.ancestors[b.ancestors.length - 1] : b.id;
        const parentIdA = ancestorCountA > 0 ? a.ancestors[0] : -1;
        const parentIdB = ancestorCountB > 0 ? b.ancestors[0] : -1;

        if (rootIdA !== rootIdB) {
            return rootIdA - rootIdB;
        } else if (ancestorCountA === ancestorCountB && parentIdA !== parentIdB) {
            return parentIdA - parentIdB;
        } else if (ancestorCountA > ancestorCountB) {
            return parentIdA - b.id;
        } else if (ancestorCountA < ancestorCountB) {
            return a.id - parentIdB;
        }
        return a.id - b.id;
    }

    // contents.sort(compare);
    contents.sort((a, b) => a.page !== b.page ? a.page - b.page : a.order - b.order);

    return contents;
}