import React from "react";
import './LoginPage.css';
import Background from "../../components/Login/common/Background";
import Logo from "../../components/Logo/Logo";
import PasswordReset from "../../components/Login/PasswordReset/PasswordReset";
import LoginSpacing from "../../components/Login/common/LoginSpacing";
import LoginDivider from "../../components/Login/common/LoginDivider";
import InfoPanel from "../../components/Login/common/InfoPanel";

export default function PasswordResetPage(props) {
    return (
        <>
            <Background />
            <div className="login-container shadow border-0">
                <Logo />
                <PasswordReset showMessage={props.showMessage} showError={props.showError} />
                <LoginSpacing />
                <LoginDivider />
                <LoginSpacing />
                <InfoPanel />
            </div>
        </>
    );
}