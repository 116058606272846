import React, {useCallback, useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import LoginTitle from "../common/LoginTitle";
import LoginTextField from "../common/LoginTextField";
import LoginSpacing from "../common/LoginSpacing";
import {LoginAuxiliaryButton, LoginButton} from "../common/LoginButtons";
import {passwordReset} from "../../../api/AuthApi";
import {redirectToLogin} from "../../../utils/navUtils";

function PasswordReset(props) {
    const [email, setEmail] = useState('');

    const [errorMessage, setErrorMessage] = useState('');

    const handleError = useCallback((message) => {
        setErrorMessage(message);
    }, []);

    useEffect(() => {
        if (errorMessage) {
            handleError(errorMessage);
            setErrorMessage(null);
        }
    }, [errorMessage, handleError]);

    const handleSubmit = async e => {
        e.preventDefault();

        setErrorMessage(null);

        const response = await passwordReset({email: email});

        if (response.type === 'error') {
            setErrorMessage('Napaka pri pošiljanju zahtevka za ponastavitev gesla');
        } else {
            props.showMessage('Zahtevek za ponastavitev gesla poslan');
            redirectToLogin(props.history);
        }
    }

    return (
        <>
            <LoginTitle title="Pozabljeno geslo" />
            <form className="password-reset-form" onSubmit={handleSubmit}>
                <LoginTextField id="email" title="E-pošta" type="email" onChange={e => setEmail(e.target.value)} />
                <LoginSpacing />
                <LoginButton errorMessage={errorMessage}>Ponastavi geslo</LoginButton>
                <LoginSpacing />
                <LoginAuxiliaryButton onClick={() => redirectToLogin(props.history)}>Nazaj na prijavo</LoginAuxiliaryButton>
            </form>
        </>
    );
}

export default withRouter(PasswordReset);