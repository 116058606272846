import React, {useCallback, useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import PageTitle from "../common/PageTitle";
import {initialPageData, LoadingTable} from "../common/LoadingTable";
import BookDetails from "./BookDetails";
import BookToc from "./BookToc";
import useToken from "../../../utils/hooks/useToken";
import {getBooks} from "../../api/AdminApi";
import {formattedDate} from "../../../utils/dateUtils";

export default function BooksTable() {
    const { token } = useToken();

    const [bookDetails, setBookDetails] = useState(undefined);
    const [showNewBookDialog, setShowNewBookDialog] = useState(false);

    const [bookTocDetails, setBookTocDetails] = useState(undefined);

    const [books, setBooks] = useState(undefined);
    const [pageData, setPageData] = useState(initialPageData());
    const [isLoading, setLoading] = useState(true);

    const booksCallback = useCallback(() => {
        setLoading(true);
        getBooks(token, pageData.currentPage, pageData.pageSize)
            .then(data => setBooks(data))
            .finally(() => setLoading(false));
    }, [token, pageData]);

    useEffect(() => {
        booksCallback();
    }, [booksCallback]);

    function rowProvider(book) {
        const tags = book.tags.map(t => t.name).join(", ");

        return (<BookRow
            key={book.id}
            rowNo={book.id}
            title={book.name}
            categories={tags}
            publishedFrom={formattedDate(book.validFrom)}
            publishedTo={formattedDate(book.validTo)}
            shopId={book.shopId}
            onRowClick={rowClicked}
            onEditToc={editToc} />);
    }

    function handleNavChange(page, pageSize) {
        setPageData(pageData => ({ ...pageData, currentPage: page, pageSize: pageSize }));
    }

    function rowClicked(row) {
        setBookDetails(books.find(book => book.id === row));
    }

    function editToc(e, row) {
        e.stopPropagation();
        setBookTocDetails(books.find(book => book.id === row));
    }

    function addBookButtonClicked() {
        setShowNewBookDialog(true);
    }

    function bookDialogClosed() {
        booksCallback();
        setShowNewBookDialog(false);
        setBookDetails(undefined);
        setBookTocDetails(undefined);
    }

    return (
        <>
            <PageTitle title="Knjige">
                <Button onClick={addBookButtonClicked}>Dodaj knjigo</Button>
            </PageTitle>

            <LoadingTable
                isLoading={isLoading}
                initialPageSize={pageData.pageSize}
                columns={['#', 'Naslov', 'Skupine knjig', 'Prikazano od', 'Prikazano do', 'Koda v trgovini', '']}
                items={books}
                rowProvider={rowProvider}
                onNavChange={handleNavChange} />

            {bookDetails && (<BookDetails show={true} bookData={bookDetails} onHide={bookDialogClosed} />)}

            {bookTocDetails && (<BookToc show={true} bookData={bookTocDetails} onHide={bookDialogClosed} />)}

            {showNewBookDialog && (<BookDetails show={showNewBookDialog} onHide={bookDialogClosed} />)}
        </>
    )
}

function BookRow({ rowNo, title, categories, publishedFrom, publishedTo, shopId, onRowClick, onEditToc }) {
    return (
        <tr onClick={() => onRowClick(rowNo)} style={{ cursor: 'pointer' }}>
            <td>{rowNo}</td>
            <td>{title}</td>
            <td>{categories}</td>
            <td>{publishedFrom}</td>
            <td>{publishedTo}</td>
            <td>{shopId}</td>
            <td><button className="btn btn-link" style={{ padding: "0" }} onClick={(e) => onEditToc(e, rowNo)}>Kazalo</button></td>
        </tr>
    )
}