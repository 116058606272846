import React from "react";
import {isoDateString} from "../../../utils/dateUtils";

export function LabeledInput({ type = 'text', pattern = null, label, fieldId, value, readOnly = false, showNullValues = false, onChange }) {
    const finalLabel = label.replace(/ /g, "\u00a0");
    const finalValue = showNullValues || value ? value : '';

    return (
        <div className="d-flex">
            <label htmlFor={fieldId} className="col-form-label">{finalLabel}:&nbsp;&nbsp;&nbsp;</label>
            <input type={type} className="form-control" id={fieldId} value={finalValue} {...(pattern ? { pattern: `${pattern}` } : {})} readOnly={readOnly} onChange={onChange} />
        </div>
    );
}

export function LabeledDateInput({ label, fieldId, value, onChange }) {
    return (
        <LabeledInput type='date' label={label} fieldId={fieldId} value={isoDateString(value)} onChange={onChange} />
    );
}

export function LabeledIntegerInput({ label, fieldId, value, showNullValues = false, onChange }) {
    return (
        <LabeledInput type='number' pattern='\d*' label={label} fieldId={fieldId} value={value} showNullValues={showNullValues} onChange={onChange} />
    );
}

export function LabeledMultilineInput({ label, fieldId, value, readOnly, onChange }) {
    const finalLabel = label.replace(/ /g, "\u00a0");

    return (
        <div className="d-flex">
            <label htmlFor={fieldId} className="col-form-label">{finalLabel}:&nbsp;&nbsp;&nbsp;</label>
            <textarea className="form-control" id={fieldId} value={value} readOnly={readOnly} onChange={onChange} />
        </div>
    );
}