import React, {useState} from "react";
import PropTypes from "prop-types";
import './InfoPanel.css';
import '../../../resources/styles/Common.css';
import LoginSpacing from "./LoginSpacing";
import Faq from "./Faq";

function InfoTitle(props) {
    return (
        <div className="info-title title main-text-color">{props.children}</div>
    );
}

function InfoButton(props) {
    return (
        <button className="info-button main-text-color text-left" onClick={props.onClick}>{props.children}</button>
    );
}

function InfoPanel() {
    const [showFaq, setShowFaq] = useState(false);

    const onClick = () => setShowFaq(true)

    return (
        <>
            <InfoTitle>Pogosto zastavljena vprašanja</InfoTitle>
            <LoginSpacing />
            <InfoButton onClick={onClick}>Kako lahko dostopam do e-učbenika?</InfoButton>
            <InfoButton onClick={onClick}>Nakup e-učbenika</InfoButton>
            <InfoButton onClick={onClick}>Nakup fizičnega učbenika</InfoButton>
            <InfoButton onClick={onClick}>Kako aktiviram e-učbenik?</InfoButton>

            <Faq show={showFaq} onHide={() => setShowFaq(false)} />
        </>
    );
}

InfoButton.propTypes = {
    onClick: PropTypes.func
}

export default InfoPanel;