import React, {useCallback, useEffect, useState} from "react";
import {generatePath, withRouter} from "react-router-dom";
import LoginTitle from "../common/LoginTitle";
import LoginTextField from "../common/LoginTextField";
import LoginSpacing from "../common/LoginSpacing";
import {LoginAuxiliaryButton, LoginButton} from "../common/LoginButtons";
import {checkCode} from "../../../api/AuthApi";
import {ACTIVATION_CODE_PATH, redirectTo, redirectToLogin} from "../../../utils/navUtils";
import ActivationInfo from "./ActivationInfo";

function Activation(props) {
    const [code, setCode] = useState('');
    const [bookName, setBookName] = useState('');
    const [tagName, setTagName] = useState('');

    const [errorMessage, setErrorMessage] = useState('');

    const handleError = useCallback((message) => {
        setErrorMessage(message);
    }, []);

    useEffect(() => {
        if (errorMessage) {
            handleError(errorMessage);
            setErrorMessage(null);
        }
    }, [errorMessage, handleError]);

    const handleChange = async e => {
        const newCode = e.target.value;
        setCode(newCode)

        setBookName('')

        if (newCode.length > 0 && newCode.length < 14) {
            setBookName('Koda ni veljavna');
        } else if (newCode.length === 14) {
            const response = await checkCode({ activation_code: newCode});

            if (response.type === 'error') {
                handleError('Nepravilna koda');
                setBookName('Koda ni veljavna');
            } else if (response.book) {
                setBookName(response.book.name);
            } else if (response.tag) {
                setTagName(response.tag.name);
            } else {
                handleError('Nepravilna koda');
                setBookName('Koda ni veljavna');
            }
        }
    }

    const handleSubmit = async e => {
        e.preventDefault();

        const payload = { activation_code: code };
        const response = await checkCode(payload);

        if (response.type === 'error') {
            handleError('Nepravilna koda');
        } else {
            const path = generatePath(ACTIVATION_CODE_PATH, { code });
            redirectTo(props.history, path);
        }
    }

    return (
        <>
            <LoginTitle title="Aktivacija e-učbenika" />
            <form className="activation-form" onSubmit={handleSubmit}>
                <LoginTextField id="code" title="Koda" onChange={handleChange} />
                <ActivationInfo hidden={code.length === 0} bookName={bookName} tagName={tagName} />
                <LoginSpacing />
                <LoginButton errorMessage={errorMessage}>Nadaljuj</LoginButton>
                <LoginSpacing />
                <LoginAuxiliaryButton onClick={() => redirectToLogin(props.history)}>Nazaj na prijavo</LoginAuxiliaryButton>
            </form>
        </>
    )
}

export default withRouter(Activation);