import React, {useCallback, useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import './BookActivationDialog.css';
import '../../resources/styles/Common.css';
import LoginTitle from "../Login/common/LoginTitle";
import LoginTextField from "../Login/common/LoginTextField";
import ActivationInfo from "../Login/Activation/ActivationInfo";
import SmallSpacing from "../common/SmallSpacing";
import {LoginAuxiliaryButton, LoginButton} from "../Login/common/LoginButtons";
import useToken from "../../utils/hooks/useToken";
import {checkCode} from "../../api/AuthApi";
import {activateBook} from "../../api/BooksApi";

export default function BookActivationDialog(props) {
    const { token } = useToken();

    const [code, setCode] = useState('');
    const [bookName, setBookName] = useState('');
    const [tagName, setTagName] = useState('');

    const isValidCode = code => code.length === 14;

    const [errorMessage, setErrorMessage] = useState('');

    const handleError = useCallback((message) => {
        setErrorMessage(message);
    }, []);

    useEffect(() => {
        if (errorMessage) {
            handleError(errorMessage);
            setErrorMessage(null);
        }
    }, [errorMessage, handleError]);

    const handleChange = async e => {
        const newCode = e.target.value;
        setCode(newCode)

        setBookName('')

        if (isValidCode(newCode)) {
            const response = await checkCode({ activation_code: newCode });

            if (response.type === 'error' || response instanceof Error) {
                setBookName('Koda ni veljavna' );
            } else if (response.book) {
                setBookName(response.book.name);
            } else if (response.tag) {
                setTagName(response.tag.name);
            }
        } else {
            setBookName('Koda ni veljavna' );
        }
    }

    const activateCode = async e => {
        e.preventDefault();

        if (!isValidCode(code)) {
            setErrorMessage('Koda ni veljavna');
            return;
        }

        const response = await activateBook(token, code);

        if (response.type === 'error' || response instanceof Error) {
            setErrorMessage('Napaka pri aktivaciji učbenika');
        } else {
            props.onHide(true);
        }
    }

    const handleKeyPress = async e => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            await activateCode(e);
        }
    }

    return (
        <Modal {...props} animation={false} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body id="book-activation-dialog-body">
                <LoginTitle title="Aktivacija e-učbenika" />
                <LoginTextField id="code" title="Koda" onChange={handleChange} onKeyPress={handleKeyPress} autoComplete="off" />
                <ActivationInfo hidden={code.length === 0} bookName={bookName} tagName={tagName} />
                <SmallSpacing />
                <SmallSpacing />
                <LoginButton onClick={activateCode} errorMessage={errorMessage}>Aktiviraj</LoginButton>
                <SmallSpacing />
                <SmallSpacing />
                <LoginAuxiliaryButton onClick={props.onHide}>Prekliči</LoginAuxiliaryButton>
                <SmallSpacing />
            </Modal.Body>
        </Modal>
    );
}