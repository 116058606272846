import React, {useCallback, useEffect, useState} from "react";
import {useParams, withRouter} from "react-router-dom";
import LoginTitle from "../common/LoginTitle";
import ActivationInfo from "./ActivationInfo";
import {LoginActivationButton, LoginAuxiliaryButton} from "../common/LoginButtons";
import LoginSpacing from "../common/LoginSpacing";
import {checkCode} from "../../../api/AuthApi";
import {redirectToLogin, redirectToRegistration} from "../../../utils/navUtils";

function ActivationCode(props) {
    let { code } = useParams();

    const [title, setTitle] = useState('');
    const [bookName, setBookName] = useState('');
    const [tagName, setTagName] = useState('');

    const codeCallback = useCallback(async () => {
        function handleInvalidCode(message) {
            setBookName('Koda ni veljavna' );
            props.showError(message);
            redirectToLogin(props.history)
        }

        setBookName('')

        if (code.length === 14) {
            const response = await checkCode({ activation_code: code});

            if (response.type === 'error') {
                handleInvalidCode('Nepravilna koda');
            } else if (response.book) {
                setTitle('Aktivacija e-učbenika');
                setBookName(response.book.name);
            } else if (response.tag) {
                setTitle('Aktivacija e-učbenikov');
                setTagName(response.tag.name);
            } else {
                handleInvalidCode('Koda ni veljavna');
            }
        } else {
            handleInvalidCode('Koda ni veljavna');
        }
    }, [code, props]);

    useEffect(() => {
        codeCallback();
    }, [codeCallback]);

    const handleClick = async (isLogin) => {
        const payload = { activation_code: code };
        const response = await checkCode(payload);

        if (response.type === 'error') {
            props.showError('Nepravilna koda');
        } else if (isLogin) {
            redirectToLogin(props.history, { ...payload });
        } else {
            redirectToRegistration(props.history, { bookName: bookName, tagName: tagName, ...payload });
        }
    }

    return (
        <>
            <LoginTitle title={title}>
                <ActivationInfo className='horizontal-center text-normal' bookName={bookName} tagName={tagName} />
            </LoginTitle>

            <LoginActivationButton onClick={() => handleClick(false)}>Računa še nimam -<br />Registracija</LoginActivationButton>
            <LoginSpacing />
            <LoginSpacing />
            <LoginActivationButton onClick={() => handleClick(true)}>Račun že imam -<br />Prijava</LoginActivationButton>
            <LoginSpacing />
            <LoginSpacing />
            <LoginAuxiliaryButton onClick={() => redirectToLogin(props.history)}>Nazaj na prijavo</LoginAuxiliaryButton>
        </>
    )
}

export default withRouter(ActivationCode);