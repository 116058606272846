import React from "react";
import './Logo.css';
import '../../resources/styles/Common.css';
import logo from "../../resources/images/logo.png";

function Logo(props) {
    return (
        <div id="logo" className="main-background-color" style={props.style}>
            <img src={logo} alt="" style={props.style} />
        </div>
    );
}

export default Logo;