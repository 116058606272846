import React from "react";
import PropTypes from "prop-types";
import './LoginErrorMessage.css';
import '../../../resources/styles/Common.css';

export default function LoginErrorMessage(props) {
    return (
        <div className="login-error-message light-text-color vertical-center horizontal-center">
            {props.message}
        </div>
    );
}

LoginErrorMessage.propTypes = {
    message: PropTypes.string,
}