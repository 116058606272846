import React from "react";
import {Col, Row} from "react-bootstrap";

export default function Spacing({ className }) {
    return (
        <Row className={className}>
            <Col>
                &nbsp;
            </Col>
        </Row>
    )
}