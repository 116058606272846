export function formattedDate(date, delimiter = '.') {
    if (!isValidDate(date)) return null;

    const d = new Date(date);
    const year = d.getFullYear();
    const month = d.getMonth() + 1;
    const day = d.getDate();

    return `${day}${delimiter}${month}${delimiter}${year}`;
}

export function isValidDate(value) {
    if (!value) return null;
    const dateWrapper = new Date(value);
    return !isNaN(dateWrapper.getDate());
}

export function dateAfter(days) {
    const date = new Date();
    date.setDate(date.getDate() + days);
    return date;
}

export function todayString() {
    return new Date().toISOString().split('T')[0];
}

export function isoDateString(dateTimeString) {
    const d = dateTimeString ? new Date(dateTimeString) : null;
    return d ? d.getFullYear().toString() + '-' + (d.getMonth()+1).toString().padStart(2, '0') + '-' + d.getDate().toString().padStart(2, '0') : null;
}