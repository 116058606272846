import React, {useCallback, useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import PropTypes from 'prop-types';
import LoginTitle from "../common/LoginTitle";
import LoginTextField from "../common/LoginTextField";
import LoginSpacing from "../common/LoginSpacing";
import {LoginAuxiliaryButton, LoginButton} from "../common/LoginButtons";
import {loginUser, registerUser} from "../../../api/AuthApi";
import {redirectToHome, redirectToLogin} from "../../../utils/navUtils";
import {ConflictError} from "../../../utils/errors";

function Registration(props) {
    const bookName = props.location.state?.bookName || '';
    const tagName = props.location.state?.tagName || '';
    bookName.length || tagName.length || redirectToLogin(props.history);

    const title = tagName ? 'Aktivacija e-učbenikov' : 'Aktivacija e-učbenika';
    const subTitle = tagName || bookName;

    const [state , setState] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: ""
    })

    const [errorMessage, setErrorMessage] = useState('');

    const handleError = useCallback((message) => {
        setErrorMessage(message);
    }, []);

    useEffect(() => {
        if (errorMessage) {
            handleError(errorMessage);
            setErrorMessage(null);
        }
    }, [errorMessage, handleError]);

    const handleChange = (e) => {
        const {id , value} = e.target
        setState(prevState => ({
            ...prevState,
            [id] : value
        }))
    }

    const handleSubmit = async e => {
        e.preventDefault();
        await sendDetailsToServer()
    }

    const sendDetailsToServer = async () => {
        if (!isValidForm()) return;

        handleError(null);

        const payload = {
            "email": state.email.trim(),
            "password": state.password,
            "first_name": state.firstName.trim(),
            "last_name": state.lastName.trim(),
            "activation_code": props.location.state.activation_code,
        }

        const response = await registerUser(payload);

        if (response instanceof ConflictError) {
            handleError(`Uporabnik s to e-pošto že obstaja`);
        } else if (response.type === 'error') {
            const error = response.errors && response.errors.length ? response.errors[0].msg : response.message;
            handleError(`Napaka pri registraciji uporabnika: ${error}`);
        } else {
            const credentials = { email: state.email, password: state.password }
            const user = await loginUser(credentials);
            props.setUser(user);
            redirectToHome(props.history);
        }
    }

    const isValidForm = () => {
        let error = '';

        if (!state.firstName.trim().length) error = 'Vnesti morate ime';
        else if (!state.lastName.trim().length) error = 'Vnesti morate priimek';
        else if (!state.email.trim().length) error = 'Vnesti morate e-poštni naslov';
        else if (!state.password) error = 'Vnesti morate geslo';
        else if (state.password.includes(' ')) error = 'Geslo ne sme vsebovati presledkov';
        else if (state.password.length < 6) error = 'Geslo mora vsebovati vsaj 6 znakov';
        else if (state.password.length > 20) error = 'Geslo ne sme biti daljše od 20 znakov';
        else if (state.password !== state.confirmPassword) error = 'Gesli se ne ujemata';

        if (error.length) handleError(error);

        return error.length === 0;
    }

    return (
        <>
            <LoginTitle title={title}>
                <small hidden={subTitle.length === 0} className="form-text text-muted">- {subTitle} -</small>
            </LoginTitle>
            <form className="registration-form" onSubmit={handleSubmit}>
                <LoginTextField id="firstName" title="Ime" onChange={handleChange} />
                <LoginSpacing />
                <LoginTextField id="lastName" title="Priimek" onChange={handleChange} />
                <LoginSpacing />
                <LoginTextField id="email" type="email" title="E-pošta" onChange={handleChange} />
                <LoginSpacing />
                <LoginTextField id="password" type="password" title="Geslo" onChange={handleChange} />
                <LoginSpacing />
                <LoginTextField id="confirmPassword" type="password" title="Ponovi geslo" onChange={handleChange} />
                <LoginSpacing />
                <LoginButton errorMessage={errorMessage}>Registriraj se</LoginButton>
                <LoginSpacing />
                <LoginAuxiliaryButton onClick={() => redirectToLogin(props.history)}>Nazaj na prijavo</LoginAuxiliaryButton>
            </form>

            <div className="alert alert-success mt-2" style={{display: state.successMessage ? 'block' : 'none' }} role="alert">
                {state.successMessage}
            </div>
        </>
    )
}

Registration.propTypes = {
    setUser: PropTypes.func.isRequired
}

export default withRouter(Registration);