import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import '../../../resources/styles/Common.css';

export default function TitledDropdown({ label, items, selectedTitle, defaultTitle, onSelect }) {
    return (
        <div className="d-flex">
            <div className="vertical-center">{label}:</div>
            <Dropdown onSelect={onSelect}>
                <Dropdown.Toggle variant="outline" id="dropdown-basic">
                    {selectedTitle || defaultTitle}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {Array.isArray(items) && items.map(i => <Dropdown.Item key={i.id} eventKey={i.id}>{i.name}</Dropdown.Item>)}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}