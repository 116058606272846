import React, {useCallback, useEffect, useState} from "react";
import PropTypes from "prop-types";
import './LoginButtons.css';
import LoginErrorMessage from "./LoginErrorMessage";

function LoginBaseButton(props) {
    return (
        <div className={props.rootClassName}>
            <button className={props.buttonClassNames} type={props.type || "submit"} tabIndex={props.tabIndex || 1} onClick={props.onClick}>{props.children}</button>
        </div>
    );
}

function LoginButton(props) {
    const [error, setError] = useState({ display: 'none', message: '' });
    const [errorTimeoutId, setErrorTimeoutId] = useState(-1);

    const resetError = useCallback(timeoutId => {
        setError(prevState => ({ ...prevState, display: 'none', message: '' }));
        if (timeoutId !== -1) clearTimeout(timeoutId);
        setErrorTimeoutId(-1);
    }, []);

    useEffect(() => {
        if (!props.errorMessage?.length) return;
        if (errorTimeoutId !== -1) return;

        setError(prevState => ({ ...prevState, display: 'block', message: props.errorMessage }));

        const timeout = setTimeout(() => {
            resetError(errorTimeoutId);
        }, 3000);
        setErrorTimeoutId(timeout);
    }, [props.errorMessage, errorTimeoutId, resetError]);

    return (
        <div className="login-button-container">
            <div>
                <LoginBaseButton rootClassName="login-button" buttonClassNames="main-background-color light-text-color text-larger" {...props} />
            </div>
            <div style={{ display: `${error.display}`}} onClick={() => resetError(errorTimeoutId)}>
                <LoginErrorMessage message={error.message} />
            </div>
        </div>
    );
}

function LoginButtonSmall(props) {
    return (
        <LoginBaseButton rootClassName="login-button-small" buttonClassNames="light-background-color main-text-color" type="button" {...props} />
    );
}

function LoginAuxiliaryButton(props) {
    return (
        <LoginBaseButton rootClassName="login-auxiliary-button" buttonClassNames="light-background-color main-text-color text-large" type="button" {...props} />
    );
}

function LoginActivationButton(props) {
    return (
        <LoginBaseButton rootClassName="login-activation-button" buttonClassNames="light-background-color main-text-color text-large" type="button" {...props} />
    );
}

LoginBaseButton.propTypes = {
    rootClassName: PropTypes.string,
    buttonClassNames: PropTypes.string,
    type: PropTypes.string,
    onClick: PropTypes.func,
    tabIndex: PropTypes.number,
}

LoginButton.propTypes = {
    errorMessage: PropTypes.string,
    tabIndex: PropTypes.number,
}

LoginButtonSmall.propTypes = {
    tabIndex: PropTypes.number,
}

LoginAuxiliaryButton.propTypes = {
    tabIndex: PropTypes.number,
}

export {
    LoginButton,
    LoginButtonSmall,
    LoginAuxiliaryButton,
    LoginActivationButton
}