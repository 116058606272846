import React, {useState} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import PrivateRoute from "../common/PrivateRoute";
import AdminRoute from "../common/AdminRoute";
import LoginPage from "../../pages/Login/LoginPage";
import PasswordResetPage from "../../pages/Login/PasswordResetPage";
import NewPasswordPage from "../../pages/Login/NewPasswordPage";
import ActivationCodePage from "../../pages/Login/ActivationCodePage";
import ActivationPage from "../../pages/Login/ActivationPage";
import RegistrationPage from "../../pages/Login/RegistrationPage";
import HomePage from "../../pages/HomePage";
import AdminPage from "../../admin/pages/AdminPage";
import Settings from "../Settings/Settings";
import {ACTIVATION_CODE_PATH, ACTIVATION_PATH, ADMIN_PATH, BOOK_PATH, HOME_PATH, LOGIN_PATH, LOST_PASSWORD_PATH, NEW_PASSWORD_PATH, REGISTRATION_PATH, SETTING_PATH} from "../../utils/navUtils";
import Snackbar from "../common/Snackbar";
import Book from "../Book/Book";

/**
 * -------
 * TO READ
 * -------
 * - Registration form:
 *   https://medium.com/technoetics/create-basic-login-forms-using-react-js-hooks-and-bootstrap-2ae36c15e551
 *   https://serverless-stack.com/chapters/create-the-signup-form.html
 * - Efficient callback construction:
 *   https://stackoverflow.com/questions/53215067/how-can-i-bind-function-with-hooks-in-react/53246229#53246229
 * - API hooks:
 *   https://www.digitalocean.com/community/tutorials/how-to-call-web-apis-with-the-useeffect-hook-in-react
 * - JavaScript binding:
 *   https://www.freecodecamp.org/news/this-is-why-we-need-to-bind-event-handlers-in-class-components-in-react-f7ea1a6f93eb/#:~:text=While%20working%20on%20React%2C%20you,class%20Foo%20extends%20React.
 *
 * -------
 * Sources
 * -------
 * - Routes:
 *   https://www.digitalocean.com/community/tutorials/how-to-handle-routing-in-react-apps-with-react-router
 * - Forms:
 *   https://reactjs.org/docs/forms.html
 *   https://www.digitalocean.com/community/tutorials/how-to-build-forms-in-react (also `useReducer`)
 * - Login flow:
 *   https://www.digitalocean.com/community/tutorials/how-to-add-login-authentication-to-react-applications (also custom `useToken` hook)
 */

function App() {
    const [message, updateMessage] = useState(null);
    const [errorMessage, updateErrorMessage] = useState(null);

    return (
        <Router>
            <Switch>
                <AdminRoute path={ADMIN_PATH}><AdminPage /></AdminRoute>
                <PrivateRoute path={HOME_PATH} exact={true}><HomePage /></PrivateRoute>
                <Route path={LOGIN_PATH}><LoginPage showError={updateErrorMessage} /></Route>
                <Route path={LOST_PASSWORD_PATH}><PasswordResetPage showMessage={updateMessage} showError={updateErrorMessage} /></Route>
                <Route path={NEW_PASSWORD_PATH}><NewPasswordPage showError={updateErrorMessage} /></Route>
                <Route path={ACTIVATION_CODE_PATH}><ActivationCodePage showError={updateErrorMessage} /></Route>
                <Route path={ACTIVATION_PATH}><ActivationPage showError={updateErrorMessage} /></Route>
                <Route path={REGISTRATION_PATH}><RegistrationPage showError={updateErrorMessage} /></Route>
                <PrivateRoute path={SETTING_PATH}><Settings /></PrivateRoute>
                <PrivateRoute path={BOOK_PATH}><Book /></PrivateRoute>
                <Redirect to={HOME_PATH} />
            </Switch>
            <Snackbar message={message} updateMessage={updateMessage} errorMessage={errorMessage} updateErrorMessage={updateErrorMessage} />
        </Router>
    )
}

export default App;
