const resolvePath = (object, path, defaultValue) => path
    .split(/[.[\]'"]/)
    .filter(p => p)
    .reduce((o, p) => o ? o[p] : defaultValue, object)

export function groupBy(array, groupField, valueCallback = null) {
    return array
        .reduce((result, currentItem) => {
            const key = resolvePath(currentItem, groupField, '');
            const value = valueCallback ? valueCallback(currentItem) : currentItem;
            (result[key] || (result[key] = [])).push(value);
            return result;
        }, {});
}

export function isNonEmptyArray(array) {
    return Array.isArray(array) && array.length;
}