import React, {useCallback, useEffect, useState} from "react";
import PageTitle from "../common/PageTitle";
import {initialPageData, LoadingTable} from "../common/LoadingTable";
import useToken from "../../../utils/hooks/useToken";
import {getPasswordResetRequests} from "../../api/AdminApi";

export default function PasswordResetRequestsTable() {
    const { token } = useToken();

    const [requests, setRequests] = useState(undefined);
    const [pageData, setPageData] = useState(initialPageData());
    const [isLoading, setLoading] = useState(true);

    const requestsCallback = useCallback(() => {
        setLoading(true);
        getPasswordResetRequests(token, pageData.currentPage, pageData.pageSize)
            .then(data => setRequests(data))
            .finally(() => setLoading(false));
    }, [token, pageData]);

    useEffect(() => {
        requestsCallback();
    }, [requestsCallback]);

    function rowProvider(request) {
        return (<PasswordResetRequestRow key={request.id} rowNo={request.id} {...request} />);
    }

    function handleNavChange(page, pageSize) {
        setPageData(pageData => ({ ...pageData, currentPage: page, pageSize: pageSize }));
    }

    return (
        <>
            <PageTitle title="Zahtevki za ponastavitev gesla" />

            <LoadingTable
                isLoading={isLoading}
                initialPageSize={pageData.pageSize}
                columns={['#', 'Uporabnik', 'Email', 'Uporabljeno dne', 'Akcija']}
                items={requests}
                rowProvider={rowProvider}
                onNavChange={handleNavChange} />
        </>
    )
}

function PasswordResetRequestRow({ rowNo, user, dateUsed, confirmationLink, isUsed }) {
    const body = encodeURIComponent(`Spoštovani ${user?.firstName} ${user?.lastName},\n\nZa ponastavitev gesla kliknite na: ${confirmationLink}\n\nLep pozdrav,\nJutro d.o.o.\n\n`);

    return (
        <tr>
            <td>{rowNo}</td>
            <td>{user?.firstName} {user?.lastName}</td>
            <td>{user?.email}</td>
            <td>{dateUsed}</td>
            <td>{!isUsed ? <a href={`mailto:${user?.email}?subject=Ponastavitev gesla&body=${body}`}>Pošlji email</a> : '' }</td>
        </tr>
    )
}