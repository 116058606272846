export class SessionExpiredError extends Error {
    constructor(message = "Pretečena seja") {
        super(message);
        this.name = "SessionExpiredError";
    }
}

export class NotFoundError extends Error {
    constructor(message = "Ni zadetkov") {
        super(message);
        this.name = "NotFoundError";
    }
}

export class ConflictError extends Error {
    constructor(message = "Zapis s temi podatki že obstaja") {
        super(message);
        this.name = "ConflictError";
    }
}

export class TooManyRequestsError extends Error {
    constructor(message = "Preveč poizvedb") {
        super(message);
        this.name = "TooManyRequestsError";
    }
}