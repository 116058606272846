import React from "react";
import './Books.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Container from "react-bootstrap/Container";
import Spacing from "../common/Spacing";
import Slider from "react-slick";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const settings = {
    dots: false,
    // centerPadding: "200px",
    infinite: false,
    speed: 300,
    // swipeToSlide: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    touchThreshold: 30,
    responsive: [
        {
            breakpoint: 960,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 280, // Galaxy Fold
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

export default function BookSlider({ books, onActivateBook }) {

    return (
        <Container className="books-container">
            <Spacing />
            <Spacing />
            <Spacing className="d-none d-sm-block"/>
            { books && Object.keys(books).map(i => (
                <Row key={i}>
                    <Col xs={12}>
                        <Spacing />
                        <BooksGroup groupName={i} group={books[i]} onActivateBook={onActivateBook} />
                    </Col>
                </Row>
            )) }
        </Container>
    );
}

function BooksGroup({ groupName, group, onActivateBook }) {
    return (
        <>
            <BooksGroupTitle title={groupName} />
            <Slider {...settings}>
                { group?.map(i => (
                    <BookItem key={i.id} book={i} onActivate={() => onActivateBook(i.id)} />
                )) }
            </Slider>
        </>
    )
}

function BooksGroupTitle({ title }) {
    return (
        <>
            <h3 className="font-weight-bold book-title">{ title }</h3>
        </>
    )
}

function BookItem({ book, onActivate }) {
    const encodedBookName = encodeURIComponent(book.name.toLowerCase().split(' ').join('-'));
    const linkName = `/u${book.id}-${encodedBookName}`;
    const imageUrl = book.frontPage.startsWith('http') ? book.frontPage : `/${book.frontPage}`;

    return (
        <div className="book-item">
            <a href={linkName}><Image src={imageUrl} fluid className="shadow border border-dark" /></a>
            {!book.isActive && <div className="book-overlay" />}
            <BookItemActions book={book} onActivate={onActivate} />
        </div>
    )
}

function BookItemActions({ book, onActivate }) {
    const handleBuyClick = e => {
        window.open(book.shopUrl, "_blank");
    }

    return (
        <div className="book-actions-overlay">
            <div className="h-100 vertical-center horizontal-center book-item-actions text-extra-large">
                {book.shopUrl?.startsWith('http') && <button type="button" className="font-weight-bold" onClick={handleBuyClick}>Kupi</button>}
                <button type="button" className="font-weight-bold" onClick={onActivate}>Aktiviraj</button>
            </div>
        </div>
    );
}

function PrevArrow(props) {
    const { className, onClick } = props;
    const isHidden = className.split(" ").filter(i => i.includes("disabled")).length > 0;
    return (
        <button className={`slide-arrow prev-arrow ${isHidden ? "hidden-arrow" : ""}`} onClick={onClick} />
    );
}

function NextArrow(props) {
    const { className, onClick } = props;
    const isHidden = className.split(" ").filter(i => i.includes("disabled")).length > 0;
    return (
        <button className={`slide-arrow next-arrow ${isHidden ? "hidden-arrow" : ""}`} onClick={onClick} />
    );
}