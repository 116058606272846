import React from "react";
import './LoginPage.css';
import Background from "../../components/Login/common/Background";
import Logo from "../../components/Logo/Logo";
import Registration from "../../components/Login/Activation/Registration";
import LoginSpacing from "../../components/Login/common/LoginSpacing";
import LoginDivider from "../../components/Login/common/LoginDivider";
import InfoPanel from "../../components/Login/common/InfoPanel";
import useToken from "../../utils/hooks/useToken";

function RegistrationPage(props) {
    const { setToken } = useToken();

    return (
        <>
            <Background />
            <div className="login-container shadow border-0">
                <Logo />
                <Registration setUser={setToken} showError={props.showError} />
                <LoginSpacing />
                <LoginDivider />
                <LoginSpacing />
                <InfoPanel />
            </div>
        </>
    );
}

export default RegistrationPage;