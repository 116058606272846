import React, {useCallback, useEffect, useState} from "react";
import './ActivationCodeDetails.css';
import {Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import TitledDropdown from "../common/TitledDropdown";
import {LabeledIntegerInput} from "../common/LabeledInput";
import Snackbar from "../../../components/common/Snackbar";
import useToken from "../../../utils/hooks/useToken";
import {createNewSchoolActivationCodes, getActivationCode, getSchools, getTags} from "../../api/AdminApi";
import {downloadCSV} from "../../utils/downloadUtils";

const EmptyRequest = {
    schoolId: -1,
    tagId: -1,
    count: 0
}

export default function ActivationCodesSchoolNew(props) {
    const { token } = useToken();

    const [message, updateMessage] = useState(null);

    const [schools, setSchools] = useState(undefined);
    const [tags, setTags] = useState(undefined);
    const [request, setRequest] = useState(Object.assign({}, EmptyRequest));
    const [codes, setCodes] = useState(undefined);

    const schoolsCallback = useCallback(() => {
        getSchools(token).then(data => setSchools(data));
    }, [token]);

    const tagsCallback = useCallback(() => {
        getTags(token).then(data => setTags(data));
    }, [token]);

    useEffect(() => {
        schoolsCallback();
        tagsCallback();
    }, [schoolsCallback, tagsCallback]);

    useEffect(() => {
        if (!props.show) setRequest(Object.assign({}, EmptyRequest));
    }, [props.show]);

    function schoolSelected(eventKey, event) {
        setRequest(request => ({ ...request, schoolId: eventKey }));
    }

    function tagSelected(eventKey, event) {
        setRequest(request => ({ ...request, tagId: eventKey }));
    }

    function updateCount(event) {
        setRequest(request => ({ ...request, count: event.target.value }));
    }

    async function addClicked() {
        if (!validate()) return;
        const response = await createNewSchoolActivationCodes(token, request.schoolId, request.tagId, request.count);
        const codes = await Promise.all(response?.codes?.map(c => getActivationCode(token, c.id)));
        setCodes(codes?.map(c => c.code));
    }

    function downloadClicked() {
        let data = codes.map(c => ({ 'Sola': schoolName(), 'Sekcija': tagName(), 'Koda': c }));
        downloadCSV(data, `kode - ${schoolName()}.csv`);
    }

    function validate() {
        let errorMessage = '';

        const isValidSchool = schools?.find(s => s.id == request?.schoolId) !== undefined;
        const isValidTag = tags?.find(t => t.id == request?.tagId) !== undefined;
        const isValidCount = request?.count > 0 && request?.count <= 100;

        if (!isValidSchool) errorMessage = 'Izberi šolo';
        else if (!isValidTag) errorMessage = 'Izberi skupino knjig';
        else if (!isValidCount) errorMessage = 'Število kod mora biti med 1 in 100';

        if (errorMessage.length > 0) {
            updateMessage(errorMessage);
            return false;
        }

        return true;
    }

    function schoolName() {
        return schools?.find(s => s.id == request?.schoolId)?.name
    }

    function tagName() {
        return tags?.find(t => t.id == request?.tagId)?.name
    }

    return (
        <Modal {...props} animation={false} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Nove aktivacijske kode za šolo
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TitledDropdown label='Šola' items={schools} selectedTitle={schoolName()} defaultTitle='Izberi šolo' onSelect={schoolSelected} />
                <TitledDropdown label='Skupina knjig' items={tags} selectedTitle={tagName()} defaultTitle='Izberi skupino' onSelect={tagSelected} />
                <LabeledIntegerInput label='Število kod' fieldId='codeCount' value={request?.count} onChange={updateCount} /><br />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={props.onHide}>{codes ? 'Zapri' : 'Prekliči'}</Button>
                {!codes && <Button onClick={addClicked}>Dodaj</Button>}
                {codes !== undefined && <Button onClick={downloadClicked}>Prenesi kode</Button>}
            </Modal.Footer>

            <Snackbar errorMessage={message} updateErrorMessage={updateMessage} />
        </Modal>
    );
}