import React, {useCallback, useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {LabeledInput} from "../common/LabeledInput";
import {initialPageData, LoadingTable} from "../common/LoadingTable";
import Snackbar from "../../../components/common/Snackbar";
import useToken from "../../../utils/hooks/useToken";
import {createNewSchool, getActivationCode, getSchoolCodes, updateSchool} from "../../api/AdminApi";
import {formattedDate} from "../../../utils/dateUtils";
import {downloadCSV} from "../../utils/downloadUtils";

const EmptySchool = {
    name: ''
}

export default function SchoolDetails({ schoolData, ...props }) {
    const { token } = useToken();

    const [message, updateMessage] = useState(null);

    const [school, setSchool] = useState(prepareData(schoolData));

    const [codes, setCodes] = useState(undefined);
    const [pageData, setPageData] = useState(initialPageData());
    const [isLoading, setLoading] = useState(true);
    const [isNew] = useState(schoolData === undefined);

    const tagBooksCallback = useCallback(() => {
        setLoading(true);
        getSchoolCodes(token, school?.id, pageData.currentPage, pageData.pageSize)
            .then(data => setCodes(data))
            .finally(() => setLoading(false));
    }, [token, school?.id, pageData]);

    useEffect(() => {
        if (school?.id === undefined) return;
        tagBooksCallback();
    }, [tagBooksCallback, school?.id]);

    function update(key, value) {
        let updated = school;
        updated[key] = value;
        setSchool(tag => ({ ...tag, ...updated }));
    }

    function prepareData(schoolData) {
        return schoolData || EmptySchool;
    }

    async function addClicked() {
        if (!validate()) return;

        if (isNew) {
            await createNewSchool(token, school);
        } else {
            await updateSchool(token, school);
        }

        props.onHide();
    }

    async function downloadClicked() {
        const codes = await getSchoolCodes(token, school?.id);
        const activationCodes = await Promise.all(codes?.map(c => getActivationCode(token, c.id)));

        let data = codes.map(c => ({
            'Sola': school.name,
            'Uporabnik': [c.usedBy?.firstName, c.usedBy?.lastName].join(" "),
            'Email': c.usedBy?.email,
            'Sekcija': c.tag.name,
            'Aktivirano dne': formattedDate(c.used),
            'Veljavno do': formattedDate(c.validTo),
            'Koda': activationCodes.find(a => a.id === c.id)?.code || '',
        }));

        downloadCSV(data, `kode - ${school.name}.csv`);
    }

    function validate() {
        let errorMessage = '';

        if (!school.name) errorMessage = 'Vnesi ime šole';
        else if (school.name.length < 3) errorMessage = 'Ime šole mora imeti vsaj 3 znake';

        if (errorMessage.length > 0) {
            updateMessage(errorMessage);
            return false;
        }

        return true;
    }

    function rowProvider(code) {
        return (<SchoolCodeRow key={code.id} rowNo={code.id} {...code} />);
    }

    function handleNavChange(page, pageSize) {
        setPageData(pageData => ({ ...pageData, currentPage: page, pageSize: pageSize }));
    }

    return (
        <Modal {...props} animation={false} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {!isNew ? school?.name : 'Nova šola'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LabeledInput label='Ime šole' fieldId='schoolName' value={school.name} onChange={e => update('name', e.target.value)} /><br />

                {!isNew && (
                    <>
                        <p>Aktivacijske kode:</p>
                        <LoadingTable
                            isLoading={isLoading}
                            initialPageSize={pageData.pageSize}
                            columns={['#', 'Uporabnik', 'Sekcija', 'Aktivirano dne', 'Veljavno do']}
                            items={codes}
                            rowProvider={rowProvider}
                            onNavChange={handleNavChange} />
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button className="mr-auto" onClick={downloadClicked}>Prenesi kode</Button>
                <Button variant="outline-primary" onClick={props.onHide}>Prekliči</Button>
                <Button onClick={addClicked}>{isNew ? 'Dodaj' : 'Shrani'}</Button>
            </Modal.Footer>

            <Snackbar errorMessage={message} updateErrorMessage={updateMessage} />
        </Modal>
    );
}

function SchoolCodeRow({ id, usedBy, tag, used, validTo }) {
    const fullName = () => {
        const firstName = usedBy ? usedBy.firstName : '';
        const lastName = usedBy ? usedBy.lastName : '';
        return `${firstName} ${lastName}`;
    }

    return (
        <tr>
            <td>{id}</td>
            <td>{fullName()}</td>
            <td>{tag?.name}</td>
            <td>{formattedDate(used)}</td>
            <td>{formattedDate(validTo)}</td>
        </tr>
    )
}