import React, {useCallback, useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import PropTypes from 'prop-types';
import './Login.css';
import '../../../resources/styles/Common.css';
import LoginTitle from "../common/LoginTitle";
import LoginTextField from "../common/LoginTextField";
import LoginSpacing from "../common/LoginSpacing";
import {LoginAuxiliaryButton, LoginButton, LoginButtonSmall} from "../common/LoginButtons";
import {loginUser} from "../../../api/AuthApi";
import {activateBook} from "../../../api/BooksApi";
import {ADMIN_PATH, HOME_PATH, redirectTo, redirectToActivation, redirectToLostPassword} from "../../../utils/navUtils";

function Login(props) {
    const activationCode = props.location.state?.activation_code;

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [errorMessage, setErrorMessage] = useState(props.location.state?.errorMessage);

    const handleError = useCallback((message) => {
        setErrorMessage(message);
    }, []);

    useEffect(() => {
        if (errorMessage) {
            handleError(errorMessage);
            setErrorMessage(null);
        }
    }, [errorMessage, handleError]);

    const activateCode = async (user) => {
        const response = await activateBook(user?.token, activationCode);

        if (response.type === 'error') {
            handleError('Napaka pri aktivaciji učbenika');
        }
    }

    const handleSubmit = async e => {
        e.preventDefault();

        handleError(null);

        const user = await loginUser({email, password});

        if (user.type === 'error') {
            handleError('Vnešeni prijavni podatki so napačni');
        } else {
            props.setUser(user);
            const pathname = user.isAdmin ? ADMIN_PATH : (props.location.state?.from?.pathname || HOME_PATH);
            if (activationCode && !user.isAdmin) await activateCode(user);
            redirectTo(props.history, pathname);
        }
    }

    return (
        <>
            <LoginTitle title="Prijava" />
            <form className="login-form" onSubmit={handleSubmit}>
                <LoginTextField id="email" title="E-pošta" type="email" onChange={e => setEmail(e.target.value)} tabIndex={1} />
                <LoginSpacing />
                <LoginTextField id="password" title="Geslo" type="password" onChange={e => setPassword(e.target.value)} tabIndex={2}>
                    <LoginButtonSmall onClick={() => redirectToLostPassword(props.history)} tabIndex={5}>Pozabljeno geslo?</LoginButtonSmall>
                </LoginTextField>
                <LoginSpacing />
                <LoginButton tabIndex={3} errorMessage={errorMessage}>Prijavi se</LoginButton>
                <LoginSpacing />
                <LoginAuxiliaryButton onClick={() => redirectToActivation(props.history)} tabIndex={4}>Aktivacija e-učbenika</LoginAuxiliaryButton>
            </form>
        </>
    );
}

Login.propTypes = {
    setUser: PropTypes.func.isRequired
}

export default withRouter(Login);