import React, {useCallback, useEffect, useState} from "react";
import './ActivationCodeDetails.css';
import {Modal} from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import useToken from "../../../utils/hooks/useToken";
import {getActivationCode} from "../../api/AdminApi";
import {formattedDate} from "../../../utils/dateUtils";

export default function ActivationCodeDetails({ codeData, ...props }) {
    const { token } = useToken();

    const [activationCode, setActivationCode] = useState(undefined);

    const activationCodeCallback = useCallback(() => {
        getActivationCode(token, codeData?.id).then(data => setActivationCode(data));
    }, [token, codeData?.id]);

    useEffect(() => {
        if (codeData?.id === undefined) return;
        activationCodeCallback();
    }, [activationCodeCallback, codeData?.id]);

    return (
        <Modal {...props} animation={false} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Aktivacijska koda: {activationCode?.code}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <GroupedData title="Koda">
                    {codeData?.used &&
                    <GroupItem field="Uporabljeno" value={formattedDate(codeData?.used)} />}
                    <GroupItem field="Veljavno do" value={formattedDate(codeData?.validTo)} />
                </GroupedData>

                {codeData?.usedBy &&
                <GroupedData title="Uporabnik">
                    <GroupItem field="Ime in priimek" value={`${codeData?.usedBy?.firstName} ${codeData?.usedBy?.lastName}`} />
                    <GroupItem field="Email" value={codeData?.usedBy?.email} />
                </GroupedData>}

                {codeData?.school &&
                <GroupedData title="Šola">
                    <GroupItem field="Naziv" value={codeData?.school?.name} />
                </GroupedData>}

                {codeData?.book &&
                <GroupedData title="Knjiga">
                    <GroupItem field="Naslov" value={codeData?.book?.name} />
                </GroupedData>}

                {codeData?.tag &&
                <GroupedData title="Sekcija knjig">
                    <GroupItem field="Naslov" value={codeData?.tag?.name} />
                </GroupedData>}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Zapri</Button>
            </Modal.Footer>
        </Modal>
    );
}

function GroupedData({ title, children }) {
    return (
        <>
            <h5>{title}</h5>
            <Table borderless size="sm">
                <tbody>
                {children}
                </tbody>
            </Table>
        </>
    )
}

function GroupItem({ field, value }) {
    return (
        <tr><td className="group-item-title">{field}</td><td>{value}</td></tr>
    )
}