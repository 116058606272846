import {groupBy} from "../utils/arrayUtils";
import {apiGetCall, authorizedApiPostCall} from "./common";
import {NotFoundError} from "../utils/errors";

export async function activateBook(accessToken, activationCode) {
    return authorizedApiPostCall(accessToken, `books/activate`, {activation_code: activationCode});
}

export function getGroupedBooks(token) {
    return new Promise(async (resolve, reject) => {
        function compare(a, b) {
            if (a.tags[0].order !== b.tags[0].order) return b.tags[0].order - a.tags[0].order;
            if (!a.isActive) return 1;
            if (!b.isActive) return -1;
            return a.name.localeCompare(b.name);
        }

        const books = await getOwnBooks(token, 1).catch(error => error);

        if (books === undefined) {
            reject(new NotFoundError());
            return;
        }
        if (books instanceof Error) {
            reject(books);
            return;
        }

        books.sort(compare);

        resolve(groupBy(books, "tags[0].name"));
    });
}

async function getOwnBooks(accessToken) {
    return apiGetCall(accessToken, 'books/mine');
}

export async function getBookPages(accessToken, bookId) {
    return apiGetCall(accessToken, `books/${bookId}/pages`);
}

export async function getBookTopics(accessToken, bookId) {
    return apiGetCall(accessToken, `books/${bookId}/topics`);
}

export async function searchBook(accessToken, bookId, search) {
    return apiGetCall(accessToken, `books/${bookId}/search?search=${search}`);
}