import React, {useCallback, useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import './BookActivationDialog.css';
import '../../resources/styles/Common.css';
import LoginTitle from "../Login/common/LoginTitle";
import LoginTextField from "../Login/common/LoginTextField";
import SmallSpacing from "../common/SmallSpacing";
import {LoginAuxiliaryButton, LoginButton} from "../Login/common/LoginButtons";
import useToken from "../../utils/hooks/useToken";
import {changePassword} from "../../api/AuthApi";

export default function SettingsDialog(props) {
    const { token } = useToken();

    const [payload, setPayload] = useState({});
    const [errorMessage, setErrorMessage] = useState('');

    const handleError = useCallback((message) => {
        setErrorMessage(message);
    }, []);

    useEffect(() => {
        if (errorMessage) {
            handleError(errorMessage);
            setErrorMessage(null);
        }
    }, [errorMessage, handleError]);

    const handleChange = e => {
        const id = e.target.id;
        const value = e.target.value;

        const updated = {};
        updated[id] = value;

        setPayload(payload => ({ ...payload, ...updated }));
    }

    const triggerPasswordChange = async e => {
        e.preventDefault();

        if (!validate()) return;

        const response = await changePassword(token, payload);

        if (response.type === 'error' || response instanceof Error) {
            setErrorMessage('Napaka pri menjavi gesla');
        } else {
            props.onHide(true);
        }
    }

    const validate = () => {
        let message = '';

        if (!payload.oldPassword) message = 'Vnesti morate trenutno geslo';
        else if (!payload.newPassword) message = 'Vnesti morate novo geslo';
        else if (payload.newPassword.includes(' ')) message = 'Geslo ne sme vsebovati presledkov';
        else if (payload.newPassword.length < 6) message = 'Geslo mora vsebovati vsaj 6 znakov';
        else if (payload.newPassword.length > 20) message = 'Geslo ne sme biti daljše od 20 znakov';
        else if (payload.newPassword !== payload.repeatNewPassword) message = 'Gesli se razlikujeta';

        if (message) {
            setErrorMessage(message);
            return false;
        }

        return true;
    }

    const handleKeyPress = async e => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            await triggerPasswordChange(e);
        }
    }

    return (
        <Modal {...props} animation={false} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body id="book-activation-dialog-body">
                <LoginTitle title="Nastavitve">
                    <small className="form-text text-muted">Sprememba gesla</small>
                </LoginTitle>
                <LoginTextField id="oldPassword" type="password" title="Trenutno geslo" onChange={handleChange} autoComplete="off" />
                <SmallSpacing />
                <LoginTextField id="newPassword" type="password" title="Novo geslo" onChange={handleChange} autoComplete="off" />
                <SmallSpacing />
                <LoginTextField id="repeatNewPassword" type="password" title="Ponovi novo geslo" onChange={handleChange} onKeyPress={handleKeyPress} autoComplete="off" />
                <SmallSpacing />
                <SmallSpacing />
                <LoginButton onClick={triggerPasswordChange} errorMessage={errorMessage}>Spremeni geslo</LoginButton>
                <SmallSpacing />
                <SmallSpacing />
                <LoginAuxiliaryButton onClick={props.onHide}>Prekliči</LoginAuxiliaryButton>
                <SmallSpacing />
            </Modal.Body>
        </Modal>
    );
}