import {Route, Redirect} from "react-router-dom";
import useToken from "../../utils/hooks/useToken";
import {LOGIN_PATH} from "../../utils/navUtils";

/**
 * -------
 * TO READ
 * -------
 * - Private routes:
 *   https://betterprogramming.pub/building-basic-react-authentication-e20a574d5e71  <-  TODO: maybe update to this implementation (it appears cleaner)
 *   https://mistryakshar54.medium.com/route-protection-in-react-using-react-router-dom-with-a-simple-example-53dc80460b0b
 *   https://reactrouter.com/web/example/auth-workflow
 * - useAuth hook:
 *   https://usehooks.com/useAuth/
 *
 * -------
 * Sources
 * -------
 * - Private routes:
 *   https://coderwhodreams.com/blog/creating-private-routes-and-handling-session-in-react-js/
 */

function PrivateRoute({ children, ...rest}) {
    const { token } = useToken();

    return (
        <Route {...rest} render={({ location }) => token ? (children) : (<Redirect to={{ pathname: LOGIN_PATH, state: { from: location} }}/>)}>
        </Route>
    );
}

export default PrivateRoute;